export const GET_PERSONS_START = "GET_PERSONS_START";
export const GET_PERSONS_SUCCESS = "GET_PERSONS_SUCCESS";
export const GET_PERSONS_FAIL = "GET_PERSONS_FAIL";

export const GET_ADMIN_PERSONS_START = "GET_ADMIN_PERSONS_START";
export const GET_ADMIN_PERSONS_SUCCESS = "GET_ADMIN_PERSONS_SUCCESS";
export const GET_ADMIN_PERSONS_FAIL = "GET_ADMIN_PERSONS_FAIL";

export const ADD_PERSON_START = "ADD_PERSON_START";
export const ADD_PERSON_SUCCESS = "ADD_PERSON_SUCCESS";
export const ADD_PERSON_FAIL = "ADD_PERSON_FAIL";

export const UPDATE_PERSON_START = "UPDATE_PERSON_START";
export const UPDATE_PERSON_SUCCESS = "UPDATE_PERSON_SUCCESS";
export const UPDATE_PERSON_FAIL = "UPDATE_PERSON_FAIL";

export const DELETE_PERSON_START = "DELETE_PERSON_START";
export const DELETE_PERSON_SUCCESS = "DELETE_PERSON_SUCCESS";
export const DELETE_PERSON_FAIL = "DELETE_PERSON_FAIL";

export const RESET_PERSON_PASSWORD_START = "RESET_PERSON_PASSWORD_START";
export const RESET_PERSON_PASSWORD_SUCCESS = "RESET_PERSON_PASSWORD_SUCCESS";
export const RESET_PERSON_PASSWORD_FAIL = "RESET_PERSON_PASSWORD_FAIL";

export const UPDATE_PERSON_ROLES_START = "UPDATE_PERSON_ROLES_START";
export const UPDATE_PERSON_ROLES_SUCCESS = "UPDATE_PERSON_ROLES_SUCCESS";
export const UPDATE_PERSON_ROLES_FAIL = "UPDATE_PERSON_ROLES_FAIL";

export const EXPORT_PERSONS_DATA_START = "EXPORT_PERSONS_DATA_START";
export const EXPORT_PERSONS_DATA_SUCCESS = "EXPORT_PERSONS_DATA_SUCCESS";
export const EXPORT_PERSONS_DATA_FAIL = "EXPORT_PERSONS_DATA_FAIL";

export const IMPORT_PERSONS_DATA_START = "IMPORT_PERSONS_DATA_START";
export const IMPORT_PERSONS_DATA_SUCCESS = "IMPORT_PERSONS_DATA_SUCCESS";
export const IMPORT_PERSONS_DATA_FAIL = "IMPORT_PERSONS_DATA_FAIL";

export const EXPORT_PERSONS_PROFILES_START = "EXPORT_PERSONS_PROFILES_START";
export const EXPORT_PERSONS_PROFILES_SUCCESS = "EXPORT_PERSONS_PROFILES_SUCCESS";
export const EXPORT_PERSONS_PROFILES_FAIL = "EXPORT_PERSONS_PROFILES_FAIL";

export const IMPORT_PERSONS_PROFILES_START = "IMPORT_PERSONS_PROFILES_START";
export const IMPORT_PERSONS_PROFILES_SUCCESS = "IMPORT_PERSONS_PROFILES_SUCCESS";
export const IMPORT_PERSONS_PROFILES_FAIL = "IMPORT_PERSONS_PROFILES_FAIL";

export const GENERATE_PERSON_PROFILE_REPORTS_START = "GENERATE_PERSON_PROFILE_REPORTS_START";
export const GENERATE_PERSON_PROFILE_REPORTS_SUCCESS = "GENERATE_PERSON_PROFILE_REPORTS_SUCCESS";
export const GENERATE_PERSON_PROFILE_REPORTS_FAIL = "GENERATE_PERSON_PROFILE_REPORTS_FAIL";

export const GENERATE_PROFILE_REPORTS_START = "GENERATE_PROFILE_REPORTS_START";
export const GENERATE_PROFILE_REPORTS_SUCCESS = "GENERATE_PROFILE_REPORTS_SUCCESS";
export const GENERATE_PROFILE_REPORTS_FAIL = "GENERATE_PROFILE_REPORTS_FAIL";

export const ASSIGN_PERSONS_START = "ASSIGN_PERSONS_START";
export const ASSIGN_PERSONS_SUCCESS = "ASSIGN_PERSONS_SUCCESS";
export const ASSIGN_PERSONS_FAIL = "ASSIGN_PERSONS_FAIL";

export const UNASSIGN_PERSONS_START = "UNASSIGN_PERSONS_START";
export const UNASSIGN_PERSONS_SUCCESS = "UNASSIGN_PERSONS_SUCCESS";
export const UNASSIGN_PERSONS_FAIL = "UNASSIGN_PERSONS_FAIL";

export const INVITE_PERSONS_START = "INVITE_PERSONS_START";
export const INVITE_PERSONS_SUCCESS = "INVITE_PERSONS_SUCCESS";
export const INVITE_PERSONS_FAIL = "INVITE_PERSONS_FAIL";

export const GET_ADMIN_COMPANY_BRANDING_LOGO_START = "GET_ADMIN_COMPANY_BRANDING_LOGO_START";
export const GET_ADMIN_COMPANY_BRANDING_LOGO_SUCCESS = "GET_ADMIN_COMPANY_BRANDING_LOGO_SUCCESS";
export const GET_ADMIN_COMPANY_BRANDING_LOGO_FAIL = "GET_ADMIN_COMPANY_BRANDING_LOGO_FAIL";

export const UPDATE_COMPANY_BRANDING_LOGO_START = "UPDATE_COMPANY_BRANDING_LOGO_START";
export const UPDATE_COMPANY_BRANDING_LOGO_SUCCESS = "UPDATE_COMPANY_BRANDING_LOGO_SUCCESS";
export const UPDATE_COMPANY_BRANDING_LOGO_FAIL = "UPDATE_COMPANY_BRANDING_LOGO_FAIL";

export const GET_ADMIN_COMPANY_FAVICON_START = "GET_ADMIN_COMPANY_FAVICON_START";
export const GET_ADMIN_COMPANY_FAVICON_SUCCESS = "GET_ADMIN_COMPANY_FAVICON_SUCCESS";
export const GET_ADMIN_COMPANY_FAVICON_FAIL = "GET_ADMIN_COMPANY_FAVICON_FAIL";

export const UPDATE_COMPANY_FAVICON_START = "UPDATE_COMPANY_FAVICON_START";
export const UPDATE_COMPANY_FAVICON_SUCCESS = "UPDATE_COMPANY_FAVICON_SUCCESS";
export const UPDATE_COMPANY_FAVICON_FAIL = "UPDATE_COMPANY_FAVICON_FAIL";

export const GET_RELATIONSHIP_TYPES_START = "GET_RELATIONSHIP_TYPES_START";
export const GET_RELATIONSHIP_TYPES_SUCCESS = "GET_RELATIONSHIP_TYPES_SUCCESS";
export const GET_RELATIONSHIP_TYPES_FAIL = "GET_RELATIONSHIP_TYPES_FAIL";

export const ADD_RELATIONSHIPS_START = "ADD_RELATIONSHIPS_START";
export const ADD_RELATIONSHIPS_SUCCESS = "ADD_RELATIONSHIPS_SUCCESS";
export const ADD_RELATIONSHIPS_FAIL = "ADD_RELATIONSHIPS_FAIL";

export const DELETE_RELATIONSHIPS_START = "DELETE_RELATIONSHIPS_START";
export const DELETE_RELATIONSHIPS_SUCCESS = "DELETE_RELATIONSHIPS_SUCCESS";
export const DELETE_RELATIONSHIPS_FAIL = "DELETE_RELATIONSHIPS_FAIL";

export const EXPORT_RELATIONSHIPS_DATA_START = "EXPORT_RELATIONSHIPS_DATA_START";
export const EXPORT_RELATIONSHIPS_DATA_SUCCESS = "EXPORT_RELATIONSHIPS_DATA_SUCCESS";
export const EXPORT_RELATIONSHIPS_DATA_FAIL = "EXPORT_RELATIONSHIPS_DATA_FAIL";

export const IMPORT_RELATIONSHIPS_DATA_START = "IMPORT_RELATIONSHIPS_DATA_START";
export const IMPORT_RELATIONSHIPS_DATA_SUCCESS = "IMPORT_RELATIONSHIPS_DATA_SUCCESS";
export const IMPORT_RELATIONSHIPS_DATA_FAIL = "IMPORT_RELATIONSHIPS_DATA_FAIL";

export const GET_EMAIL_TEMPLATE_TYPES_START = "GET_EMAIL_TEMPLATE_TYPES_START";
export const GET_EMAIL_TEMPLATE_TYPES_SUCCESS = "GET_EMAIL_TEMPLATE_TYPES_SUCCESS";
export const GET_EMAIL_TEMPLATE_TYPES_FAIL = "GET_EMAIL_TEMPLATE_TYPES_FAIL";

export const GET_EMAIL_TEMPLATE_BY_TYPE_START = "GET_EMAIL_TEMPLATE_BY_TYPE_START";
export const GET_EMAIL_TEMPLATE_BY_TYPE_SUCCESS = "GET_EMAIL_TEMPLATE_BY_TYPE_SUCCESS";
export const GET_EMAIL_TEMPLATE_BY_TYPE_FAIL = "GET_EMAIL_TEMPLATE_BY_TYPE_FAIL";

export const CREATE_UPDATE_EMAIL_TEMPLATE_START = "CREATE_UPDATE_EMAIL_TEMPLATE_START";
export const CREATE_UPDATE_EMAIL_TEMPLATE_SUCCESS = "CREATE_UPDATE_EMAIL_TEMPLATE_SUCCESS";
export const CREATE_UPDATE_EMAIL_TEMPLATE_FAIL = "CREATE_UPDATE_EMAIL_TEMPLATE_FAIL";

export const GET_INTERVENTIONS_START = "GET_INTERVENTIONS_START";
export const GET_INTERVENTIONS_SUCCESS = "GET_INTERVENTIONS_SUCCESS";
export const GET_INTERVENTIONS_FAIL = "GET_INTERVENTIONS_FAIL";

export const ADD_INTERVENTION_START = "ADD_INTERVENTION_START";
export const ADD_INTERVENTION_SUCCESS = "ADD_INTERVENTION_SUCCESS";
export const ADD_INTERVENTION_FAIL = "ADD_INTERVENTION_FAIL";

export const UPDATE_INTERVENTION_START = "UPDATE_INTERVENTION_START";
export const UPDATE_INTERVENTION_SUCCESS = "UPDATE_INTERVENTION_SUCCESS";
export const UPDATE_INTERVENTION_FAIL = "UPDATE_INTERVENTION_FAIL";

export const DELETE_INTERVENTION_START = "DELETE_INTERVENTION_START";
export const DELETE_INTERVENTION_SUCCESS = "DELETE_INTERVENTION_SUCCESS";
export const DELETE_INTERVENTION_FAIL = "DELETE_INTERVENTION_FAIL";

export const DELETE_INTERVENTIONS_START = "DELETE_INTERVENTIONS_START";
export const DELETE_INTERVENTIONS_SUCCESS = "DELETE_INTERVENTIONS_SUCCESS";
export const DELETE_INTERVENTIONS_FAIL = "DELETE_INTERVENTIONS_FAIL";

export const EXPORT_INTERVENTIONS_DATA_START = "EXPORT_INTERVENTIONS_DATA_START";
export const EXPORT_INTERVENTIONS_DATA_SUCCESS = "EXPORT_INTERVENTIONS_DATA_SUCCESS";
export const EXPORT_INTERVENTIONS_DATA_FAIL = "EXPORT_INTERVENTIONS_DATA_FAIL";

export const IMPORT_INTERVENTIONS_DATA_START = "IMPORT_INTERVENTIONS_DATA_START";
export const IMPORT_INTERVENTIONS_DATA_SUCCESS = "IMPORT_INTERVENTIONS_DATA_SUCCESS";
export const IMPORT_INTERVENTIONS_DATA_FAIL = "IMPORT_INTERVENTIONS_DATA_FAIL";

export const ASSIGN_INTERVENTION_JOB_START = "ASSIGN_INTERVENTION_JOB_START";
export const ASSIGN_INTERVENTION_JOB_SUCCESS = "ASSIGN_INTERVENTION_JOB_SUCCESS";
export const ASSIGN_INTERVENTION_JOB_FAIL = "ASSIGN_INTERVENTION_JOB_FAIL";

export const DELETE_INTERVENTION_JOB_START = "DELETE_INTERVENTION_JOB_START";
export const DELETE_INTERVENTION_JOB_SUCCESS = "DELETE_INTERVENTION_JOB_SUCCESS";
export const DELETE_INTERVENTION_JOB_FAIL = "DELETE_INTERVENTION_JOB_FAIL";

export const DELETE_INTERVENTION_JOBS_START = "DELETE_INTERVENTION_JOBS_START";
export const DELETE_INTERVENTION_JOBS_SUCCESS = "DELETE_INTERVENTION_JOBS_SUCCESS";
export const DELETE_INTERVENTION_JOBS_FAIL = "DELETE_INTERVENTION_JOBS_FAIL";

export const GET_ACTIONTYPES_START = "GET_ACTIONTYPES_START";
export const GET_ACTIONTYPES_SUCCESS = "GET_ACTIONTYPES_SUCCESS";
export const GET_ACTIONTYPES_FAIL = "GET_ACTIONTYPES_FAIL";

export const ADD_ACTIONTYPE_START = "ADD_ACTIONTYPE_START";
export const ADD_ACTIONTYPE_SUCCESS = "ADD_ACTIONTYPE_SUCCESS";
export const ADD_ACTIONTYPE_FAIL = "ADD_ACTIONTYPE_FAIL";

export const UPDATE_ACTIONTYPE_START = "UPDATE_ACTIONTYPE_START";
export const UPDATE_ACTIONTYPE_SUCCESS = "UPDATE_ACTIONTYPE_SUCCESS";
export const UPDATE_ACTIONTYPE_FAIL = "UPDATE_ACTIONTYPE_FAIL";

export const DELETE_ACTIONTYPE_START = "DELETE_ACTIONTYPE_START";
export const DELETE_ACTIONTYPE_SUCCESS = "DELETE_ACTIONTYPE_SUCCESS";
export const DELETE_ACTIONTYPE_FAIL = "DELETE_ACTIONTYPE_FAIL";

export const GET_EVIDENCETYPES_START = "GET_EVIDENCETYPES_START";
export const GET_EVIDENCETYPES_SUCCESS = "GET_EVIDENCETYPES_SUCCESS";
export const GET_EVIDENCETYPES_FAIL = "GET_EVIDENCETYPES_FAIL";

export const ADD_EVIDENCETYPE_START = "ADD_EVIDENCETYPE_START";
export const ADD_EVIDENCETYPE_SUCCESS = "ADD_EVIDENCETYPE_SUCCESS";
export const ADD_EVIDENCETYPE_FAIL = "ADD_EVIDENCETYPE_FAIL";

export const UPDATE_EVIDENCETYPE_START = "UPDATE_EVIDENCETYPE_START";
export const UPDATE_EVIDENCETYPE_SUCCESS = "UPDATE_EVIDENCETYPE_SUCCESS";
export const UPDATE_EVIDENCETYPE_FAIL = "UPDATE_EVIDENCETYPE_FAIL";

export const DELETE_EVIDENCETYPE_START = "DELETE_EVIDENCETYPE_START";
export const DELETE_EVIDENCETYPE_SUCCESS = "DELETE_EVIDENCETYPE_SUCCESS";
export const DELETE_EVIDENCETYPE_FAIL = "DELETE_EVIDENCETYPE_FAIL";

export const GET_PAGE_ASSIGNMENTS_START = "GET_PAGE_ASSIGNMENTS_START";
export const GET_PAGE_ASSIGNMENTS_SUCCESS = "GET_PAGE_ASSIGNMENTS_SUCCESS";
export const GET_PAGE_ASSIGNMENTS_FAIL = "GET_PAGE_ASSIGNMENTS_FAIL";
export const CLEAR_PAGE_ASSIGNMENTS = "CLEAR_PAGE_ASSIGNMENTS";

export const GET_PERSON_PAGE_ASSIGNMENTS_START = "GET_PERSON_PAGE_ASSIGNMENTS_START";
export const GET_PERSON_PAGE_ASSIGNMENTS_SUCCESS = "GET_PERSON_PAGE_ASSIGNMENTS_SUCCESS";
export const GET_PERSON_PAGE_ASSIGNMENTS_FAIL = "GET_PERSON_PAGE_ASSIGNMENTS_FAIL";

export const UPDATE_PERSON_PAGE_ASSIGNMENTS_START = "UPDATE_PERSON_PAGE_ASSIGNMENTS_START";
export const UPDATE_PERSON_PAGE_ASSIGNMENTS_SUCCESS = "UPDATE_PERSON_PAGE_ASSIGNMENTS_SUCCESS";
export const UPDATE_PERSON_PAGE_ASSIGNMENTS_FAIL = "UPDATE_PERSON_PAGE_ASSIGNMENTS_FAIL";

export const GET_ADMIN_COLUMN_DISPLAY_SETTINGS_START = "GET_ADMIN_COLUMN_DISPLAY_SETTINGS_START";
export const GET_ADMIN_COLUMN_DISPLAY_SETTINGS_SUCCESS = "GET_ADMIN_COLUMN_DISPLAY_SETTINGS_SUCCESS";
export const GET_ADMIN_COLUMN_DISPLAY_SETTINGS_FAIL = "GET_ADMIN_COLUMN_DISPLAY_SETTINGS_FAIL";

export const UPDATE_ADMIN_COLUMN_DISPLAY_SETTING_START = "UPDATE_ADMIN_COLUMN_DISPLAY_SETTING_START";
export const UPDATE_ADMIN_COLUMN_DISPLAY_SETTING_SUCCESS = "UPDATE_ADMIN_COLUMN_DISPLAY_SETTING_SUCCESS";
export const UPDATE_ADMIN_COLUMN_DISPLAY_SETTING_FAIL = "UPDATE_ADMIN_COLUMN_DISPLAY_SETTING_FAIL";

export const CLEAR_COLUMN_DISPLAY_SETTINGS = "CLEAR_COLUMN_DISPLAY_SETTINGS";

export const GET_CONTENT_PAGES_START = "GET_CONTENT_PAGES_START";
export const GET_CONTENT_PAGES_SUCCESS = "GET_CONTENT_PAGES_SUCCESS";
export const GET_CONTENT_PAGES_FAIL = "GET_CONTENT_PAGES_FAIL";

export const GET_CONTENTS_START = "GET_CONTENTS_START";
export const GET_CONTENTS_SUCCESS = "GET_CONTENTS_SUCCESS";
export const GET_CONTENTS_FAIL = "GET_CONTENTS_FAIL";

export const GET_CONTENT_TRANSLATIONS_START = "GET_ADMIN_CONTENT_TRANSLATIONS_START";
export const GET_CONTENT_TRANSLATIONS_SUCCESS = "GET_ADMIN_CONTENT_TRANSLATIONS_SUCCESS";
export const GET_CONTENT_TRANSLATIONS_FAIL = "GET_ADMIN_CONTENT_TRANSLATIONS_FAIL";

export const EXPORT_CONTENT_TRANSLATIONS_START = "EXPORT_CONTENT_TRANSLATIONS_START";
export const EXPORT_CONTENT_TRANSLATIONS_SUCCESS = "EXPORT_CONTENT_TRANSLATIONS_SUCCESS";
export const EXPORT_CONTENT_TRANSLATIONS_FAIL = "EXPORT_CONTENT_TRANSLATIONS_FAIL";

export const IMPORT_CONTENT_TRANSLATIONS_START = "IMPORT_CONTENT_TRANSLATIONS_START";
export const IMPORT_CONTENT_TRANSLATIONS_SUCCESS = "IMPORT_CONTENT_TRANSLATIONS_SUCCESS";
export const IMPORT_CONTENT_TRANSLATIONS_FAIL = "IMPORT_CONTENT_TRANSLATIONS_FAIL";

export const CREATE_CONTENT_TRANSLATION_START = "CREATE_CONTENT_TRANSLATION_START";
export const CREATE_CONTENT_TRANSLATION_SUCCESS = "CREATE_CONTENT_TRANSLATION_SUCCESS";
export const CREATE_CONTENT_TRANSLATION_FAIL = "CREATE_CONTENT_TRANSLATION_FAIL";

export const UPDATE_CONTENT_TRANSLATION_START = "UPDATE_CONTENT_TRANSLATION_START";
export const UPDATE_CONTENT_TRANSLATION_SUCCESS = "UPDATE_CONTENT_TRANSLATION_SUCCESS";
export const UPDATE_CONTENT_TRANSLATION_FAIL = "UPDATE_CONTENT_TRANSLATION_FAIL";

export const GET_EXTRAFRAMEWORK_CAPABILITIES_START = "GET_EXTRAFRAMEWORK_CAPABILITIES_START";
export const GET_EXTRAFRAMEWORK_CAPABILITIES_SUCCESS = "GET_EXTRAFRAMEWORK_CAPABILITIES_SUCCESS";
export const GET_EXTRAFRAMEWORK_CAPABILITIES_FAIL = "GET_EXTRAFRAMEWORK_CAPABILITIES_FAIL";

export const GET_EXTRAFRAMEWORK_LEVELS_START = "GET_EXTRAFRAMEWORK_LEVELS_START";
export const GET_EXTRAFRAMEWORK_LEVELS_SUCCESS = "GET_EXTRAFRAMEWORK_LEVELS_SUCCESS";
export const GET_EXTRAFRAMEWORK_LEVELS_FAIL = "GET_EXTRAFRAMEWORK_LEVELS_FAIL";

export const CREATE_EXTRAFRAMEWORK_LEVEL_START = "CREATE_EXTRAFRAMEWORK_LEVEL_START";
export const CREATE_EXTRAFRAMEWORK_LEVEL_SUCCESS = "CREATE_EXTRAFRAMEWORK_LEVEL_SUCCESS";
export const CREATE_EXTRAFRAMEWORK_LEVEL_FAIL = "CREATE_EXTRAFRAMEWORK_LEVEL_FAIL";

export const UPDATE_EXTRAFRAMEWORK_LEVEL_START = "UPDATE_EXTRAFRAMEWORK_LEVEL_START";
export const UPDATE_EXTRAFRAMEWORK_LEVEL_SUCCESS = "UPDATE_EXTRAFRAMEWORK_LEVEL_SUCCESS";
export const UPDATE_EXTRAFRAMEWORK_LEVEL_FAIL = "UPDATE_EXTRAFRAMEWORK_LEVEL_FAIL";

export const DELETE_EXTRAFRAMEWORK_LEVEL_START = "DELETE_EXTRAFRAMEWORK_LEVEL_START";
export const DELETE_EXTRAFRAMEWORK_LEVEL_SUCCESS = "DELETE_EXTRAFRAMEWORK_LEVEL_SUCCESS";
export const DELETE_EXTRAFRAMEWORK_LEVEL_FAIL = "DELETE_EXTRAFRAMEWORK_LEVEL_FAIL";

export const MOVE_EXTRAFRAMEWORK_LEVELS_START = "MOVE_EXTRAFRAMEWORK_LEVELS_START";
export const MOVE_EXTRAFRAMEWORK_LEVELS_SUCCESS = "MOVE_EXTRAFRAMEWORK_LEVELS_SUCCESS";
export const MOVE_EXTRAFRAMEWORK_LEVELS_FAIL = "MOVE_EXTRAFRAMEWORK_LEVELS_FAIL";

export const GET_EXTRAFRAMEWORK_CATEGORIES_START = "GET_EXTRAFRAMEWORK_CATEGORIES_START";
export const GET_EXTRAFRAMEWORK_CATEGORIES_SUCCESS = "GET_EXTRAFRAMEWORK_CATEGORIES_SUCCESS";
export const GET_EXTRAFRAMEWORK_CATEGORIES_FAIL = "GET_EXTRAFRAMEWORK_CATEGORIES_FAIL";

export const CREATE_EXTRAFRAMEWORK_CATEGORY_START = "CREATE_EXTRAFRAMEWORK_CATEGORY_START";
export const CREATE_EXTRAFRAMEWORK_CATEGORY_SUCCESS = "CREATE_EXTRAFRAMEWORK_CATEGORY_SUCCESS";
export const CREATE_EXTRAFRAMEWORK_CATEGORY_FAIL = "CREATE_EXTRAFRAMEWORK_CATEGORY_FAIL";

export const UPDATE_EXTRAFRAMEWORK_CATEGORY_START = "UPDATE_EXTRAFRAMEWORK_CATEGORY_START";
export const UPDATE_EXTRAFRAMEWORK_CATEGORY_SUCCESS = "UPDATE_EXTRAFRAMEWORK_CATEGORY_SUCCESS";
export const UPDATE_EXTRAFRAMEWORK_CATEGORY_FAIL = "UPDATE_EXTRAFRAMEWORK_CATEGORY_FAIL";

export const DELETE_EXTRAFRAMEWORK_CATEGORY_START = "DELETE_EXTRAFRAMEWORK_CATEGORY_START";
export const DELETE_EXTRAFRAMEWORK_CATEGORY_SUCCESS = "DELETE_EXTRAFRAMEWORK_CATEGORY_SUCCESS";
export const DELETE_EXTRAFRAMEWORK_CATEGORY_FAIL = "DELETE_EXTRAFRAMEWORK_CATEGORY_FAIL";

export const CREATE_EXTRAFRAMEWORK_SUBCATEGORY_START = "CREATE_EXTRAFRAMEWORK_SUBCATEGORY_START";
export const CREATE_EXTRAFRAMEWORK_SUBCATEGORY_SUCCESS = "CREATE_EXTRAFRAMEWORK_SUBCATEGORY_SUCCESS";
export const CREATE_EXTRAFRAMEWORK_SUBCATEGORY_FAIL = "CREATE_EXTRAFRAMEWORK_SUBCATEGORY_FAIL";

export const UPDATE_EXTRAFRAMEWORK_SUBCATEGORY_START = "UPDATE_EXTRAFRAMEWORK_SUBCATEGORY_START";
export const UPDATE_EXTRAFRAMEWORK_SUBCATEGORY_SUCCESS = "UPDATE_EXTRAFRAMEWORK_SUBCATEGORY_SUCCESS";
export const UPDATE_EXTRAFRAMEWORK_SUBCATEGORY_FAIL = "UPDATE_EXTRAFRAMEWORK_SUBCATEGORY_FAIL";

export const DELETE_EXTRAFRAMEWORK_SUBCATEGORY_START = "DELETE_EXTRAFRAMEWORK_SUBCATEGORY_START";
export const DELETE_EXTRAFRAMEWORK_SUBCATEGORY_SUCCESS = "DELETE_EXTRAFRAMEWORK_SUBCATEGORY_SUCCESS";
export const DELETE_EXTRAFRAMEWORK_SUBCATEGORY_FAIL = "DELETE_EXTRAFRAMEWORK_SUBCATEGORY_FAIL";

export const CREATE_EXTRAFRAMEWORK_SKILL_START = "CREATE_EXTRAFRAMEWORK_SKILL_START";
export const CREATE_EXTRAFRAMEWORK_SKILL_SUCCESS = "CREATE_EXTRAFRAMEWORK_SKILL_SUCCESS";
export const CREATE_EXTRAFRAMEWORK_SKILL_FAIL = "CREATE_EXTRAFRAMEWORK_SKILL_FAIL";

export const UPDATE_EXTRAFRAMEWORK_SKILL_START = "UPDATE_EXTRAFRAMEWORK_SKILL_START";
export const UPDATE_EXTRAFRAMEWORK_SKILL_SUCCESS = "UPDATE_EXTRAFRAMEWORK_SKILL_SUCCESS";
export const UPDATE_EXTRAFRAMEWORK_SKILL_FAIL = "UPDATE_EXTRAFRAMEWORK_SKILL_FAIL";

export const DELETE_EXTRAFRAMEWORK_SKILL_START = "DELETE_EXTRAFRAMEWORK_SKILL_START";
export const DELETE_EXTRAFRAMEWORK_SKILL_SUCCESS = "DELETE_EXTRAFRAMEWORK_SKILL_SUCCESS";
export const DELETE_EXTRAFRAMEWORK_SKILL_FAIL = "DELETE_EXTRAFRAMEWORK_SKILL_FAIL";

export const EXPORT_EXTRAFRAMEWORKS_DATA_START = "EXPORT_EXTRAFRAMEWORKS_DATA_START";
export const EXPORT_EXTRAFRAMEWORKS_DATA_SUCCESS = "EXPORT_EXTRAFRAMEWORKS_DATA_SUCCESS";
export const EXPORT_EXTRAFRAMEWORKS_DATA_FAIL = "EXPORT_EXTRAFRAMEWORKS_DATA_FAIL";

export const IMPORT_EXTRAFRAMEWORKS_DATA_START = "IMPORT_EXTRAFRAMEWORKS_DATA_START";
export const IMPORT_EXTRAFRAMEWORKS_DATA_SUCCESS = "IMPORT_EXTRAFRAMEWORKS_DATA_SUCCESS";
export const IMPORT_EXTRAFRAMEWORKS_DATA_FAIL = "IMPORT_EXTRAFRAMEWORKS_DATA_FAIL";

export const UPDATE_PERSON_ENDORSEMENT = "UPDATE_PERSON_ENDORSEMENT";

export const UPDATE_PERSON_SELFASSESSED_APPROVED = "UPDATE_PERSON_SELFASSESSED_APPROVED";
export const UPDATE_PERSON_SELFASSESSED_POSTPONED = "UPDATE_PERSON_SELFASSESSED_POSTPONED";

export const UPDATE_PERSON_ENDORSER_START = "UPDATE_PERSON_ENDORSER_START";
export const UPDATE_PERSON_ENDORSER_SUCCESS = "UPDATE_PERSON_ENDORSER_SUCCESS";
export const UPDATE_PERSON_ENDORSER_FAIL = "UPDATE_PERSON_ENDORSER_FAIL";

export const GET_BADGE_REQUESTS_START = "GET_BADGE_REQUESTS_START";
export const GET_BADGE_REQUESTS_SUCCESS = "GET_BADGE_REQUESTS_SUCCESS";
export const GET_BADGE_REQUESTS_FAIL = "GET_BADGE_REQUESTS_FAIL";

export const UPDATE_BADGE_REQUEST_ASSESSOR_START = "UPDATE_BADGE_REQUEST_ASSESSOR_START";
export const UPDATE_BADGE_REQUEST_ASSESSOR_SUCCESS = "UPDATE_BADGE_REQUEST_ASSESSOR_SUCCESS";
export const UPDATE_BADGE_REQUEST_ASSESSOR_FAIL = "UPDATE_BADGE_REQUEST_ASSESSOR_FAIL";

export const UPDATE_BADGE_REQUEST_CERTIFICATION_ENTRIES_START = "UPDATE_BADGE_REQUEST_CERTIFICATION_ENTRIES_START";
export const UPDATE_BADGE_REQUEST_CERTIFICATION_ENTRIES_SUCCESS = "UPDATE_BADGE_REQUEST_CERTIFICATION_ENTRIES_SUCCESS";
export const UPDATE_BADGE_REQUEST_CERTIFICATION_ENTRIES_FAIL = "UPDATE_BADGE_REQUEST_CERTIFICATION_ENTRIES_FAIL";

export const SUBMIT_BADGE_REQUEST_CERTIFICATION_START = "SUBMIT_BADGE_REQUEST_CERTIFICATION_START";
export const SUBMIT_BADGE_REQUEST_CERTIFICATION_SUCCESS = "SUBMIT_BADGE_REQUEST_CERTIFICATION_SUCCESS";
export const SUBMIT_BADGE_REQUEST_CERTIFICATION_FAIL = "SUBMIT_BADGE_REQUEST_CERTIFICATION_FAIL";

export const SUBMIT_BADGE_REQUEST_CERTIFICATIONS_START = "SUBMIT_BADGE_REQUEST_CERTIFICATIONS_START";
export const SUBMIT_BADGE_REQUEST_CERTIFICATIONS_SUCCESS = "SUBMIT_BADGE_REQUEST_CERTIFICATIONS_SUCCESS";
export const SUBMIT_BADGE_REQUEST_CERTIFICATIONS_FAIL = "SUBMIT_BADGE_REQUEST_CERTIFICATIONS_FAIL";

export const REJECT_BADGE_REQUEST_CERTIFICATION_START = "REJECT_BADGE_REQUEST_CERTIFICATION_START";
export const REJECT_BADGE_REQUEST_CERTIFICATION_SUCCESS = "REJECT_BADGE_REQUEST_CERTIFICATION_SUCCESS";
export const REJECT_BADGE_REQUEST_CERTIFICATION_FAIL = "REJECT_BADGE_REQUEST_CERTIFICATION_FAIL";

export const UPDATE_BADGE_REQUEST = "UPDATE_BADGE_REQUEST";

export const GET_NO_OF_ACTIVE_ACTION_PROMOTIONS_START = "GET_NO_OF_ACTIVE_ACTION_PROMOTIONS_START";
export const GET_NO_OF_ACTIVE_ACTION_PROMOTIONS_SUCCESS = "GET_NO_OF_ACTIVE_ACTION_PROMOTIONS_SUCCESS";
export const GET_NO_OF_ACTIVE_ACTION_PROMOTIONS_FAIL = "GET_NO_OF_ACTIVE_ACTION_PROMOTIONS_FAIL";

export const GET_ACTION_PROMOTIONS_START = "GET_ACTION_PROMOTIONS_START";
export const GET_ACTION_PROMOTIONS_SUCCESS = "GET_ACTION_PROMOTIONS_SUCCESS";
export const GET_ACTION_PROMOTIONS_FAIL = "GET_ACTION_PROMOTIONS_FAIL";

export const UPDATE_ACTION_PROMOTION_START = "UPDATE_ACTION_PROMOTION_START";
export const UPDATE_ACTION_PROMOTION_SUCCESS = "UPDATE_ACTION_PROMOTION_SUCCESS";
export const UPDATE_ACTION_PROMOTION_FAIL = "UPDATE_ACTION_PROMOTION_FAIL";

export const ACCEPT_ACTION_PROMOTION_START = "ACCEPT_ACTION_PROMOTION_START";
export const ACCEPT_ACTION_PROMOTION_SUCCESS = "ACCEPT_ACTION_PROMOTION_SUCCESS";
export const ACCEPT_ACTION_PROMOTION_FAIL = "ACCEPT_ACTION_PROMOTION_FAIL";

export const DECLINE_ACTION_PROMOTION_START = "DECLINE_ACTION_PROMOTION_START";
export const DECLINE_ACTION_PROMOTION_SUCCESS = "DECLINE_ACTION_PROMOTION_SUCCESS";
export const DECLINE_ACTION_PROMOTION_FAIL = "DECLINE_ACTION_PROMOTION_FAIL";

export const GET_ALLOWED_REGISTRATION_EMAIL_DOMAINS_START = "GET_ALLOWED_REGISTRATION_EMAIL_DOMAINS_START";
export const GET_ALLOWED_REGISTRATION_EMAIL_DOMAINS_SUCCESS = "GET_ALLOWED_REGISTRATION_EMAIL_DOMAINS_SUCCESS";
export const GET_ALLOWED_REGISTRATION_EMAIL_DOMAINS_FAIL = "GET_ALLOWED_REGISTRATION_EMAIL_DOMAINS_FAIL";

export const ADD_ALLOWED_REGISTRATION_EMAIL_DOMAIN_START = "ADD_ALLOWED_REGISTRATION_EMAIL_DOMAIN_START";
export const ADD_ALLOWED_REGISTRATION_EMAIL_DOMAIN_SUCCESS = "ADD_ALLOWED_REGISTRATION_EMAIL_DOMAIN_SUCCESS";
export const ADD_ALLOWED_REGISTRATION_EMAIL_DOMAIN_FAIL = "ADD_ALLOWED_REGISTRATION_EMAIL_DOMAIN_FAIL";

export const UPDATE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_START = "UPDATE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_START";
export const UPDATE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_SUCCESS = "UPDATE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_SUCCESS";
export const UPDATE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_FAIL = "UPDATE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_FAIL";

export const DELETE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_START = "DELETE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_START";
export const DELETE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_SUCCESS = "DELETE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_SUCCESS";
export const DELETE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_FAIL = "DELETE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_FAIL";

export const GET_REGISTRATION_REASONS_START = "GET_REGISTRATION_REASONS_START";
export const GET_REGISTRATION_REASONS_SUCCESS = "GET_REGISTRATION_REASONS_SUCCESS";
export const GET_REGISTRATION_REASONS_FAIL = "GET_REGISTRATION_REASONS_FAIL";

export const ADD_REGISTRATION_REASON_START = "ADD_REGISTRATION_REASON_START";
export const ADD_REGISTRATION_REASON_SUCCESS = "ADD_REGISTRATION_REASON_SUCCESS";
export const ADD_REGISTRATION_REASON_FAIL = "ADD_REGISTRATION_REASON_FAIL";

export const UPDATE_REGISTRATION_REASON_START = "UPDATE_REGISTRATION_REASON_START";
export const UPDATE_REGISTRATION_REASON_SUCCESS = "UPDATE_REGISTRATION_REASON_SUCCESS";
export const UPDATE_REGISTRATION_REASON_FAIL = "UPDATE_REGISTRATION_REASON_FAIL";

export const DELETE_REGISTRATION_REASON_START = "DELETE_REGISTRATION_REASON_START";
export const DELETE_REGISTRATION_REASON_SUCCESS = "DELETE_REGISTRATION_REASON_SUCCESS";
export const DELETE_REGISTRATION_REASON_FAIL = "DELETE_REGISTRATION_REASON_FAIL";

export const MOVE_REGISTRATION_REASON_START = "MOVE_REGISTRATION_REASON_START";
export const MOVE_REGISTRATION_REASON_SUCCESS = "MOVE_REGISTRATION_REASON_SUCCESS";
export const MOVE_REGISTRATION_REASON_FAIL = "MOVE_REGISTRATION_REASON_FAIL";

export const GET_ACTION_LOGS_START = "GET_ACTION_LOGS_START";
export const GET_ACTION_LOGS_SUCCESS = "GET_ACTION_LOGS_SUCCESS";
export const GET_ACTION_LOGS_FAIL = "GET_ACTION_LOGS_FAIL";

export const EXPORT_ACTION_LOGS_START = "EXPORT_ACTION_LOGS_START";
export const EXPORT_ACTION_LOGS_SUCCESS = "EXPORT_ACTION_LOGS_SUCCESS";
export const EXPORT_ACTION_LOGS_FAIL = "EXPORT_ACTION_LOGS_FAIL";

export const REQUEST_COPY_SELF_PROFILES_AS_ENDORSED_START = "REQUEST_COPY_SELF_PROFILES_AS_ENDORSED_START";
export const REQUEST_COPY_SELF_PROFILES_AS_ENDORSED_SUCCESS = "REQUEST_COPY_SELF_PROFILES_AS_ENDORSED_SUCCESS";
export const REQUEST_COPY_SELF_PROFILES_AS_ENDORSED_FAIL = "REQUEST_COPY_SELF_PROFILES_AS_ENDORSED_FAIL";

export const UPDATE_COPY_SELF_PROFILES_AS_ENDORSED_STATUS = "UPDATE_COPY_SELF_PROFILES_AS_ENDORSED_STATUS";

export const GET_MENTOR_PREFERENCES_START = "GET_MENTOR_PREFERENCES_START";
export const GET_MENTOR_PREFERENCES_SUCCESS = "GET_MENTOR_PREFERENCES_SUCCESS";
export const GET_MENTOR_PREFERENCES_FAIL = "GET_MENTOR_PREFERENCES_FAIL";

export const ADD_MENTOR_PREFERENCE_START = "ADD_MENTOR_PREFERENCE_START";
export const ADD_MENTOR_PREFERENCE_SUCCESS = "ADD_MENTOR_PREFERENCE_SUCCESS";
export const ADD_MENTOR_PREFERENCE_FAIL = "ADD_MENTOR_PREFERENCE_FAIL";

export const UPDATE_MENTOR_PREFERENCE_START = "UPDATE_MENTOR_PREFERENCE_START";
export const UPDATE_MENTOR_PREFERENCE_SUCCESS = "UPDATE_MENTOR_PREFERENCE_SUCCESS";
export const UPDATE_MENTOR_PREFERENCE_FAIL = "UPDATE_MENTOR_PREFERENCE_FAIL";

export const DELETE_MENTOR_PREFERENCE_START = "DELETE_MENTOR_PREFERENCE_START";
export const DELETE_MENTOR_PREFERENCE_SUCCESS = "DELETE_MENTOR_PREFERENCE_SUCCESS";
export const DELETE_MENTOR_PREFERENCE_FAIL = "DELETE_MENTOR_PREFERENCE_FAIL";

export const APPROVE_SKILL_PROFILES_START = "APPROVE_SKILL_PROFILES_START";
export const APPROVE_SKILL_PROFILES_SUCCESS = "APPROVE_SKILL_PROFILES_SUCCESS";
export const APPROVE_SKILL_PROFILES_FAIL = "APPROVE_SKILL_PROFILES_FAIL";
