import { Layout } from "../components/Layout";
import Survey from "../components/assessment/Survey";
import SurveyAssessment from "../components/assessment/SurveyAssessment";
import SurveyQuestion from "../components/assessment/SurveyQuestion";
import * as contentActions from "../store/actions/contentAction";
import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { Col, Modal, ModalBody, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;

class SelfAssessmentPage extends Component {
    static displayName = SelfAssessmentPage.name;

    constructor(props) {
        super(props);
        this.connection = null;
        this.state = {
            showRotate: false,
            contentLoading: true,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.checkDeviceOrientation);
        this.checkDeviceOrientation();

        if (this.props.language && this.props.language.name == defaultLanguage) {
            this.props.onContent.getContentTranslations(this.props.language.id, "SfiaAll-Menu-Assessments-Assessment").then(() => {
                this.setState({ contentLoading: this.props.contentLoading });
                this.props.onContent.getContentTranslations(this.props.language.id, "Question-Values-Elements-Modules").then(() => {});
            });
        } else {
            this.props.onContent.getContentTranslations(this.props.language.id, "SfiaAll-Menu-Assessments-Assessment-Question-Values-Elements-Modules").then(() => {
                this.setState({ contentLoading: this.props.contentLoading });
            });
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    checkDeviceOrientation = () => {
        if (window.matchMedia("(orientation: portrait)").matches && isMobile) {
            this.setState({ showRotate: true });
        } else if (window.matchMedia("(orientation: landscape)").matches) {
            this.setState({ showRotate: false });
        }
    };

    closeRotateModal = () => {
        this.setState({ showRotate: false });
    };

    renderRotateNotification = () => {
        return (
            <Modal isOpen={this.state.showRotate} centered={true} onClick={() => this.closeRotateModal()}>
                <ModalBody>
                    <div style={{ textAlign: "center" }}>
                        <img src={process.env.PUBLIC_URL + "/assets/img/rotate.gif"} style={{ height: "100px" }} />
                        <br />
                        <span style={{ fontSize: "16px" }}>PLEASE ROTATE YOUR DEVICE</span>
                        <br />
                        <span style={{ fontSize: "12px" }}>(OR CLICK TO CONTINUE)</span>
                    </div>
                </ModalBody>
            </Modal>
        );
    };

    render() {
        return (
            <Layout language={this.props.language} setCurrentLanguage={this.props.setCurrentLanguage}>
                {this.renderRotateNotification()}
                {this.state.contentLoading ? (
                    <Row>
                        <Col>
                            <div className="p-col-12" style={{ textAlign: "center" }}>
                                <Spinner color="dark" />
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <Switch>
                        <Route exact path="/survey/" component={Survey} />
                        <Route exact path="/survey/assessment/:assessmentId" component={SurveyAssessment} />
                        <Route path="/survey/question/:componentGroupId/:elementId" component={SurveyQuestion} />
                    </Switch>
                )}
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language.language,
        contentTranslations: state.content.contentTranslations,
        contentLoading: state.content.loading,
        contentError: state.content.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onContent: bindActionCreators(contentActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelfAssessmentPage);
