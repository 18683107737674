import { Row, Col, Select, Form, Switch } from "antd";
import React from "react";

const constantData = {
  empty: "",
  labelPersonName: "Person",
  personName: "person",
  endVersionName: "endVersion",
  startVersionName: "startVersion",
  labelStartVersionName: "Start Version",
  labelEndVersionName: "End Version",
  noDatText: " (No Data)",
  labelProfileTypeName: "Profile Type",
};

// A reusable select component that reduces redundancy
const SelectComponent = ({
  value,
  onChange,
  placeholderBaseText,
  options,
  disabled,
}) => {
  // Constructing the placeholder text based on the passed options
  const placeholderText = `${placeholderBaseText}${
    (options ?? []).length < 1 ? constantData.noDatText : constantData.empty
  }`;

  return (
    <Select
      allowClear
      showSearch
      style={{ width: "100%" }}
      size="middle"
      value={value}
      options={options}
      disabled={disabled}
      onChange={onChange}
      placeholder={placeholderText}
    />
  );
};

const SkillChangeFilters = ({
  objPerson,
  objStartVersion,
  objEndVersion,
  objCompact,
  objProfileType
}) => {
  // Destructuring properties to avoid repeated optional chaining
  const { value: personValue, setValue: setPersonValue } = objPerson || {};
  const { value: profileTypeValue, setValue: setProfileTypeValue } =
    objProfileType || {};
  const { value: startVersionValue, setValue: setStartVersionValue } =
    objStartVersion || {};
  const { value: endVersionValue, setValue: setEndVersionValue } =
    objEndVersion || {};
  const { value: compactValue, setValue: setCompactValue } = objCompact || {};

  // Defining clear conditions for component behaviors
  const isStartVersionDisabled = !personValue;
  const isEndVersionDisabled = !startVersionValue;
  const isSwitchDisabled = !startVersionValue || !endVersionValue;

  return (
    <>
      <Form layout="vertical">
        <Row className="layout-container justify-content-center m-1">
          <Col span={6}>
            <Form.Item label={constantData.labelPersonName}>
              {/* Using the reusable select component */}
              <SelectComponent
                value={personValue}
                onChange={(val) => setPersonValue(val)}
                placeholderBaseText="Please Select Person"
                options={objPerson?.options}
              />
            </Form.Item>
          </Col>
        </Row>    
        <Row className="layout-container justify-content-center m-1">
          <Col span={6}>
            <Form.Item label={constantData.labelProfileTypeName}>
              {/* Using the reusable select component */}
              <SelectComponent
                value={profileTypeValue}
                onChange={(val) => setProfileTypeValue(val)}
                placeholderBaseText="Please Select Profile Type"
                options={objProfileType?.options}
                disabled={isStartVersionDisabled}
              />
            </Form.Item>
          </Col>
          <Col offset={6} span={6}>
          </Col>
        </Row>    
        <Row className="layout-container justify-content-center m-1">
          <Col span={6}>
            <Form.Item label={constantData.labelStartVersionName}>
              {/* Using the reusable select component */}
              <SelectComponent
                value={startVersionValue}
                onChange={(val) => setStartVersionValue(val)}
                placeholderBaseText="Please Select Start Version"
                options={objStartVersion?.options.filter(it => it.refData.profileType == profileTypeValue)}
                disabled={isStartVersionDisabled}
              />
            </Form.Item>
          </Col>
          <Col offset={6} span={6}>
            <Form.Item label={constantData.labelEndVersionName}>
              {/* Using the reusable select component */}
              <SelectComponent
                value={endVersionValue}
                onChange={(val) => setEndVersionValue(val)}
                placeholderBaseText="Please Select End Version"
                options={objEndVersion?.options.filter(it => it.refData.profileType == profileTypeValue)}
                disabled={isEndVersionDisabled}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="layout-container justify-content-center m-1">
          <Col span={12}>
            {/* Switch for compact view, only enabled when both versions are selected */}
            <Switch
              defaultChecked={compactValue ?? false}
              disabled={isSwitchDisabled}
              onChange={(val) => setCompactValue(val)}
            />
            <span className="ml-2">Check with Compact View</span>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default SkillChangeFilters;
