import * as authActions from "../../../store/actions/authAction";
import * as lorActions from "../../../store/actions/lorAction";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import { filterArray } from "../../../utils/filterUtils";
import { ContentWrapperLg } from "../../common/ContentWrapperLg";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableClearSearch } from "../../common/SkillsTxTableClearSearch";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import LorAttributeBulkUpload from "./LorAttributeBulkUpload";
import UpdateLor from "./UpdateLor";
import { faFileExcel, faPenToSquare, faSync, faSquarePen, faChevronLeft, faPlus, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, ButtonGroup, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

const headerStyle = {
    color: "#2B2B2B",
    fontSize: "28px",
    fontFamily: "IBMPlexSans-Bold",
};

const manageAttributeWrapper = {
    width: "100%",
    maxWidth: "100%",
    fontSize: "11px",
};

class ManageLor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            lors: [],
            searchProperties: ["name", "category", "lorCode", "strattributes"],
            showLoadingPanel: false,
            showDialogImportTs: null,
            editMode: false,
            editedLor: null,
            editAttribute: false,
            optionHidden: [
                { label: "Yes", value: true },
                { label: "No", value: false },
            ],
            editedAttribute: null,
            lorAttributeIsUpdating: false,
        };

        this.filterArray = filterArray.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.editAttributeAnchor = React.createRef();
    }

    scroll(ref) {
        ref.current.scrollIntoView({ behavior: "smooth" });
    }

    componentDidMount() {
        this.props.onAuth.authCheckState().then(() => {
            this.initData();
        });
    }

    componentWillUnmount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.lors !== this.props.lors) {
            this.setState({
                lors: this.reformLors(this.props.lors),
            });
        }
    }

    initData = () => {
        this.props.onLor.getLors().then(() => {
            const lors = this.reformLors(this.props.lors);

            this.setState({
                lors,
            });

            this.setState({ loading: this.props.loading || this.props.lorLoading });
        });
    };

    reformLors = (lors) => {
        return lors.map((it) => {
            return {
                id: it.id,
                name: it.name,
                description: it.description,
                lorCode: it.lorCode,
                category: it.category != null ? it.category : "Generic Attributes",
                categoryColor: it.category != null ? it.categoryColor : "#69849c",
                lorColor: it.category != null ? it.lorColor : "#ecf0f5",
                sequence: it.sequence,
                canHide: it.canHide,
                isHidden: it.isHidden,
                levels: it.levels,
                optional: it.optional,
                attributes: it.attributes ? it.attributes : [],
                strattributes: it.attributes
                    ? it.attributes
                          .map((it) => {
                              return it.attribute;
                          })
                          .sort((a, b) => (a > b ? 1 : -1))
                          .join(", ")
                    : "",
            };
        });
    };

    handleRefresh = () => {
        this.initData();
    };

    handleClearSelection = () => {
        const interventions = this.state.interventions;

        interventions.forEach((it) => {
            it.toggleSelected = false;
        });

        this.setState({ interventions: [...interventions] });
    };

    onTableFilterChange = (e, searchType) => {
        let columnSearchKeywords = this.state.columnSearchKeywords;
        if (!columnSearchKeywords) {
            columnSearchKeywords = [];
        }

        if (columnSearchKeywords.find((it) => it.name == e.target.name)) {
            let columnSearchKeyword = columnSearchKeywords.find((it) => it.name == e.target.name);
            columnSearchKeyword.value = e.target.value;
        } else {
            columnSearchKeywords.push({
                name: e.target.name,
                value: e.target.value,
                searchType,
            });
        }

        this.setState({ columnSearchKeywords });
    };

    openEditLor = (lor) => {
        this.setState({ showDialogLorDetailTs: new Date(), selectedLor: lor });
    };

    handleHideLor = (lor) => {
        this.setState({ showLoadingPanel: true, loadingPanelHeader: null, loadingPanelBody: "Please wait ..." });
        this.props.onLor.updateLorSetIsHidden(lor.id, !lor.isHidden).then(() => {
            this.setState({ showLoadingPanel: this.props.isUpdating });
        });
    };

    handleLorOptional = (lor) => {
        this.setState({ showLoadingPanel: true, loadingPanelHeader: null, loadingPanelBody: "Please wait ..." });
        this.props.onLor.updateLorSetOptional(lor.id, !lor.optional).then(() => {
            this.setState({ showLoadingPanel: this.props.isUpdating });
        });
    };

    renderLoadingPanel() {
        if (this.state.showLoadingPanel) {
            return (
                <Modal isOpen={this.state.showLoadingPanel}>
                    {this.state.loadingPanelHeader && <ModalHeader>{this.state.loadingPanelHeader}</ModalHeader>}
                    <ModalBody>
                        <Spinner size="md" color="primary" /> {this.state.loadingPanelBody}
                    </ModalBody>
                </Modal>
            );
        } else {
            return "";
        }
    }

    pad2 = (n) => {
        return (n < 10 ? "0" : "") + n;
    };

    handleExportAttributes = () => {
        this.props.onLor.exportLorAttributesDataExcel().then(() => {
            if (!this.props.loadingExport && !this.props.lorError) {
                if (this.props.exportData) {
                    var today = new Date();
                    var strToday = today.getFullYear() + this.pad2(today.getMonth() + 1) + this.pad2(today.getDate()) + "_" + this.pad2(today.getHours()) + this.pad2(today.getMinutes()) + this.pad2(today.getSeconds());
                    FileDownload(this.props.exportData, `ExportLorAttributes_${strToday}.xlsx`);
                }
            }
        });
    };

    handleImportAttributes = () => {
        this.setState({ showDialogImportTs: new Date() });
    };

    handleImportSuccess = () => {
        this.props.onLor.getLors().then(() => {
            if (!(this.props.loading || this.props.lorLoading)) {
                const lors = this.reformLors(this.props.lors);

                this.setState({
                    lors,
                });
            }

            this.setState({ loading: this.props.loading || this.props.lorLoading });
        });
    };

    openEditLorAttribute = (lor) => {
        this.setState({
            editMode: true,
            editedLor: {
                id: lor.id,
                name: lor.name,
                lorCode: lor.lorCode,
                attributes: [...lor.attributes],
            },
        });
        setTimeout(() => {
            this.scroll(this.editAttributeAnchor);
        }, 200);
    };

    handleBackToList = () => {
        this.setState({ editMode: false });
    };

    handleAddNewAttribute = () => {
        this.setState({ editAttribute: true, editedAttribute: { id: null, attribute: null, link: null, isHidden: false } });
    };

    handleEditAttribute = (attribute) => {
        this.setState({
            editAttribute: true,
            editedAttribute: {
                id: attribute.id,
                attribute: attribute.attribute,
                link: attribute.link,
                isHidden: attribute.isHidden,
            },
        });
    };

    cancelUpdateAttribute = () => {
        this.setState({ editAttribute: false, editedAttribute: null });
    };

    handleChangeAttribute = (event) => {
        const { editedAttribute } = this.state;
        let { name, value } = event.target;

        if (name == "attribute") {
            editedAttribute.attribute = value;
        } else if (name == "link") {
            editedAttribute.link = value;
        } else if (name == "isHidden") {
            editedAttribute.isHidden = value;
        }

        this.setState({ editedAttribute });
    };

    handleSaveAttribute = () => {
        const { editedAttribute, editedLor } = this.state;
        if (editedAttribute.id) {
            this.setState({ lorAttributeIsUpdating: true });
            this.props.onLor
                .updateLorAttribute(editedLor.id, editedAttribute.id, {
                    attribute: editedAttribute.attribute,
                    link: editedAttribute.link,
                    isHidden: editedAttribute.isHidden,
                })
                .then(() => {
                    if (!this.props.isUpdating) {
                        if (!this.props.lorError) {
                            this.generateAlert("success", "Update attribute success.");
                            this.setState({ editAttribute: false, editedAttribute: null });
                        } else {
                            this.generateAlert("danger", this.props.lorError.errData.Message);
                        }
                    }
                    this.setState({ lorAttributeIsUpdating: this.props.isUpdating });
                });
        } else {
            this.setState({ lorAttributeIsUpdating: true });
            this.props.onLor
                .addLorAttribute(editedLor.id, {
                    attribute: editedAttribute.attribute,
                    link: editedAttribute.link,
                    isHidden: editedAttribute.isHidden,
                })
                .then(() => {
                    if (!this.props.isUpdating) {
                        if (!this.props.lorError) {
                            this.generateAlert("success", "Add attribute success.");
                            this.setState({ editAttribute: false, editedAttribute: null });
                            const lors = this.props.lors;
                            const lor = lors.find((it) => it.id == editedLor.id);
                            if (lor) {
                                editedLor.attributes = [...lor.attributes];
                            }
                            this.setState({ editedLor });
                        } else {
                            this.generateAlert("danger", this.props.lorError.errData.Message);
                        }
                    }
                    this.setState({ lorAttributeIsUpdating: this.props.isUpdating });
                });
        }
    };

    handleDeleteAttribute = (attribute) => {
        const { editedLor } = this.state;
        this.setState({ lorAttributeIsUpdating: true });
        this.props.onLor.deleteLorAttribute(editedLor.id, attribute.id).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.lorError) {
                    this.generateAlert("success", "Delete attribute success.");
                    const lors = this.props.lors;
                    const lor = lors.find((it) => it.id == editedLor.id);
                    if (lor) {
                        editedLor.attributes = [...lor.attributes];
                    }
                    this.setState({ editedLor });
                } else {
                    this.generateAlert("danger", this.props.lorError.errData.Message);
                }
            }
            this.setState({ lorAttributeIsUpdating: this.props.isUpdating });
        });
    };

    render() {
        const { lors, searchKeyword, searchProperties, columnSearchKeywords, editedLor } = this.state;

        const pageAssignments = this.props.pageAssignments;
        const pageAssignment =
            (this.props.containerConfig && this.props.containerConfig.eeaFrameworkContainer == true && pageAssignments.find((it) => it.pageName == "Manage LoR Category")) ||
            (this.props.containerConfig && this.props.containerConfig.eeaFrameworkContainer == false && pageAssignments.find((it) => it.pageName == "Manage LoR"));

        const onTableFilterChange = this.onTableFilterChange;
        const openEditLor = this.openEditLor;
        const openEditLorAttribute = this.openEditLorAttribute;
        const handleHideLor = this.handleHideLor;
        const handleLorOptional = this.handleLorOptional;

        const filtered = this.filterArray(lors, searchKeyword, searchProperties, columnSearchKeywords);

        const nonEEAContainer = this.props.containerConfig && !this.props.containerConfig.eeaFrameworkContainer; //lors && lors.find(it => it.canHide);

        const columns = [
            {
                id: "id",
                name: "Action",
                selector: (row) => row.id,
                sortable: false,
                width: "7%",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                            <React.Fragment>
                                <FontAwesomeIcon id={`iconUpdateAttributes${row.id}`} icon={faPenToSquare} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => openEditLorAttribute(row)} />
                                <UncontrolledTooltip target={`iconUpdateAttributes${row.id}`} placement="bottom">
                                    Update LoR Attributes
                                </UncontrolledTooltip>
                            </React.Fragment>
                            {!nonEEAContainer && (
                                <React.Fragment>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <FontAwesomeIcon id={`iconProviderDetail${row.id}`} icon={faSquarePen} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => openEditLor(row)} />
                                    <UncontrolledTooltip target={`iconProviderDetail${row.id}`} placement="bottom">
                                        Update LoR Category
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            )}
                        </div>
                    );
                },
                omit: pageAssignment && pageAssignment.operations && (pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) || pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true)) ? false : true,
            },
            {
                id: "name",
                name: (
                    <div style={{ width: "90%" }}>
                        Name
                        <br />
                        <input type="text" name="name" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "name") ? columnSearchKeywords.find((it) => it.name == "name").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.name,
                sortable: true,
                wrap: true,
                width: pageAssignment && pageAssignment.operations && (pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) || pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true)) ? "32%" : "39%",
            },
            {
                id: "lorCode",
                name: (
                    <div style={{ width: "90%" }}>
                        LoR Code
                        <br />
                        <input type="text" name="lorCode" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "lorCode") ? columnSearchKeywords.find((it) => it.name == "lorCode").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.lorCode,
                sortable: true,
                width: nonEEAContainer ? "10%" : "12%",
                wrap: true,
                center: true,
            },
            {
                id: "category",
                name: (
                    <div style={{ width: "90%" }}>
                        Category
                        <br />
                        <input type="text" name="category" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "category") ? columnSearchKeywords.find((it) => it.name == "category").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.category,
                sortable: true,
                width: nonEEAContainer ? "20%" : "26%",
                wrap: true,
            },
            {
                id: "attributes",
                name: (
                    <div style={{ width: "90%" }}>
                        Attributes
                        <br />
                        <input
                            type="text"
                            name="strattributes"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "strattributes") ? columnSearchKeywords.find((it) => it.name == "strattributes").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.attributes,
                sortable: true,
                width: "15%",
                wrap: true,
                format: function (row, index) {
                    return row.strattributes;
                },
            },
            {
                id: "isHidden",
                name: <div style={{ width: "90%" }}>LoR Is Shown</div>,
                selector: (row) => row.isHidden,
                sortable: false,
                width: "8%",
                wrap: true,
                center: true,
                format: function (row, index) {
                    return (
                        <React.Fragment>
                            <ButtonGroup>
                                <Button color="success" outline={row.isHidden == false ? false : true} style={{ cursor: row.canHide && row.isHidden ? "pointer" : "default" }} disabled={!row.canHide} onClick={() => row.canHide && row.isHidden && handleHideLor(row)}></Button>
                                <Button color="danger" outline={row.isHidden == true ? false : true} style={{ cursor: row.canHide && !row.isHidden ? "pointer" : "default" }} disabled={!row.canHide} onClick={() => row.canHide && !row.isHidden && handleHideLor(row)}></Button>
                            </ButtonGroup>
                        </React.Fragment>
                    );
                },
                omit: !nonEEAContainer,
            },
            {
                id: "optional",
                name: <div style={{ width: "90%" }}>LoR Is Optional</div>,
                selector: (row) => row.optional,
                sortable: false,
                width: "8%",
                wrap: true,
                center: true,
                format: function (row, index) {
                    return (
                        <React.Fragment>
                            <ButtonGroup>
                                <Button color="success" outline={row.optional == true ? false : true} style={{ cursor: !row.optional ? "pointer" : "default" }} onClick={() => !row.optional && handleLorOptional(row)}></Button>
                                <Button color="danger" outline={row.optional == false ? false : true} style={{ cursor: row.optional ? "pointer" : "default" }} onClick={() => row.optional && handleLorOptional(row)}></Button>
                            </ButtonGroup>
                        </React.Fragment>
                    );
                },
            },
        ];

        const handleEditAttribute = this.handleEditAttribute;
        const handleDeleteAttribute = this.handleDeleteAttribute;

        const attributeColumns = [
            {
                id: "attribute",
                name: "Attribute",
                selector: (row) => row.attribute,
                sortable: true,
                width: "30%",
            },
            {
                id: "link",
                name: "Link",
                selector: (row) => row.link,
                sortable: true,
                width: "40%",
                wrap: true,
            },
            {
                id: "isHidden",
                name: "Is Hidden",
                selector: (row) => row.isHidden,
                sortable: true,
                width: "15%",
                format: function (row, index) {
                    return row.isHidden ? "Yes" : "No";
                },
            },
            {
                id: "id",
                selector: (row) => row.id,
                sortable: false,
                width: "15%",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                            <FontAwesomeIcon id={`iconEditAttribute${row.id}`} icon={faPenToSquare} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => handleEditAttribute(row)} />
                            <UncontrolledTooltip target={`iconEditAttribute${row.id}`} placement="bottom">
                                Edit Attribute
                            </UncontrolledTooltip>
                            &nbsp;&nbsp;&nbsp;
                            {!(row.accountSkillAttributes && row.accountSkillAttributes.length > 0) && (
                                <React.Fragment>
                                    <FontAwesomeIcon id={`iconDeleteAttribute${row.id}`} icon={faMinusCircle} size="lg" style={{ cursor: "pointer", color: "#FF0000" }} onClick={() => handleDeleteAttribute(row)} />
                                    <UncontrolledTooltip target={`iconDeleteAttribute${row.id}`} placement="bottom">
                                        Delete Attribute
                                    </UncontrolledTooltip>
                                    &nbsp;&nbsp;&nbsp;
                                </React.Fragment>
                            )}
                        </div>
                    );
                },
            },
        ];

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.renderLoadingPanel()}
                <UpdateLor lor={this.state.selectedLor} showDialog={this.state.showDialogLorDetailTs} generateAlert={this.generateAlert}></UpdateLor>
                <LorAttributeBulkUpload handleImportSuccess={this.handleImportSuccess} showDialog={this.state.showDialogImportTs} generateAlert={this.generateAlert}></LorAttributeBulkUpload>
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : !this.state.editMode ? (
                    <React.Fragment>
                        <div style={{ marginBottom: "10px" }}>
                            <Row>
                                <Col md="4" sm="6">
                                    <SkillsTxTableSearchBar
                                        searchKeyword={this.state.searchKeyword}
                                        onChange={(searchKeyword) => {
                                            this.handleClearSelection();
                                            this.setState({ searchKeyword, selectedInterventions: [] });
                                        }}
                                    ></SkillsTxTableSearchBar>
                                </Col>
                                <Col md="8" sm="6">
                                    <SkillsTxTableClearSearch
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        color="primary"
                                        disabled={this.props.lorLoading || this.state.isUpdating}
                                        onClear={() => {
                                            this.handleClearSelection();
                                            this.setState({ searchKeyword: "", selectedInterventions: [] });
                                        }}
                                    ></SkillsTxTableClearSearch>
                                    &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        onClick={this.handleRefresh}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        disabled={this.props.lorLoading || this.state.isUpdating}
                                    >
                                        <FontAwesomeIcon icon={faSync} /> Refresh
                                    </Button>
                                    &nbsp;&nbsp;
                                    {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Export" && op.allow == true) && (
                                        <React.Fragment>
                                            <Button
                                                color="primary"
                                                onClick={this.handleExportAttributes}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.lorLoading || this.props.loadingExport}
                                            >
                                                <FontAwesomeIcon icon={faFileExcel} /> Export Attributes
                                            </Button>
                                            &nbsp;&nbsp;
                                        </React.Fragment>
                                    )}
                                    {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Import" && op.allow == true) && (
                                        <Button
                                            color="primary"
                                            onClick={this.handleImportAttributes}
                                            style={{
                                                fontSize: "16px",
                                                height: "40px",
                                                marginBottom: "10px",
                                            }}
                                            disabled={this.props.lorLoading}
                                        >
                                            <FontAwesomeIcon icon={faFileExcel} /> Import Attributes
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </div>
                        <SkillsTxTable
                            columns={columns}
                            data={lors}
                            pagination={false}
                            searchKeyword={searchKeyword}
                            searchProperties={searchProperties}
                            columnSearchKeywords={columnSearchKeywords}
                            selectableRows={false}
                            selectableRowsHighlight={false}
                            progressPending={this.props.lorLoading}
                        ></SkillsTxTable>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <ContentWrapperLg className="contentWrapperLgAdmin-Style" style={manageAttributeWrapper}>
                            <Row>
                                <Col md="12">
                                    <Row>
                                        <Col md="0-5">
                                            <Button
                                                color="primary"
                                                onClick={this.handleBackToList}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faChevronLeft} />
                                            </Button>
                                        </Col>
                                        <Col md="11-5">
                                            <p ref={this.editAttributeAnchor} className="mb-0" style={headerStyle}>
                                                Update Lor Attributes - {editedLor ? `${editedLor.name} (${editedLor.lorCode})` : ""}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>&nbsp;</Col>
                            </Row>
                            {editedLor && (
                                <React.Fragment>
                                    <Row>
                                        <Col md={this.state.editAttribute ? "6" : "12"}>
                                            <div>
                                                <Row style={{ marginBottom: "0.5rem" }}>
                                                    <Col md="12">
                                                        <Button
                                                            color="primary"
                                                            onClick={this.handleAddNewAttribute}
                                                            style={{
                                                                fontSize: "16px",
                                                                height: "40px",
                                                            }}
                                                            disabled={this.state.lorAttributeIsUpdating}
                                                        >
                                                            <FontAwesomeIcon icon={faPlus} /> Add New
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <SkillsTxTable columns={attributeColumns} data={editedLor.attributes.sort((a, b) => a.attribute > b.attribute)} pagination={false}></SkillsTxTable>
                                            </div>
                                        </Col>
                                        {this.state.editAttribute && (
                                            <Col md="6">
                                                <Form>
                                                    <Row>
                                                        <Col md={12}>
                                                            <FormGroup row>
                                                                <Label md={3} sm={12}>
                                                                    Attribute
                                                                </Label>
                                                                <Col md={9} sm={12}>
                                                                    <Input
                                                                        type="text"
                                                                        name="attribute"
                                                                        id="attribute"
                                                                        value={this.state.editedAttribute && this.state.editedAttribute.attribute ? this.state.editedAttribute.attribute : ""}
                                                                        invalid={!(this.state.editedAttribute && this.state.editedAttribute.attribute && this.state.editedAttribute.attribute != "")}
                                                                        onChange={this.handleChangeAttribute}
                                                                        disabled={this.state.lorAttributeIsUpdating}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label md={3} sm={12}>
                                                                    Link
                                                                </Label>
                                                                <Col md={9} sm={12}>
                                                                    <Input
                                                                        type="text"
                                                                        name="link"
                                                                        id="link"
                                                                        value={this.state.editedAttribute && this.state.editedAttribute.link ? this.state.editedAttribute.link : ""}
                                                                        onChange={this.handleChangeAttribute}
                                                                        disabled={this.state.lorAttributeIsUpdating}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label md={3} sm={12}>
                                                                    IsHidden
                                                                </Label>
                                                                <Col md={9} sm={12}>
                                                                    <Input type="select" name="isHidden" id="isHidden" value={this.state.editedAttribute ? this.state.editedAttribute.isHidden : false} onChange={this.handleChangeAttribute} disabled={this.state.lorAttributeIsUpdating}>
                                                                        {this.state.optionHidden.map((it, key) => (
                                                                            <option key={key} value={it.value}>
                                                                                {it.label}
                                                                            </option>
                                                                        ))}
                                                                    </Input>
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label md={3} sm={12}></Label>
                                                                <Col md={9} sm={12}>
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            this.handleSaveAttribute();
                                                                        }}
                                                                        disabled={!(this.state.editedAttribute && this.state.editedAttribute.attribute && this.state.editedAttribute.attribute != "") || this.state.lorAttributeIsUpdating}
                                                                    >
                                                                        Save
                                                                    </Button>
                                                                    &nbsp;&nbsp;
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            this.cancelUpdateAttribute();
                                                                        }}
                                                                        disabled={this.state.lorAttributeIsUpdating}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        )}
                                    </Row>
                                </React.Fragment>
                            )}
                        </ContentWrapperLg>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        authPersonId: state.auth.personId,

        lors: state.lor.lors,
        lorError: state.lor.error,
        lorLoading: state.lor.loading,
        exportData: state.lor.exportData,
        loadingExport: state.lor.loadingExport,

        pageAssignments: state.adminPerson.pageAssignments,
        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onLor: bindActionCreators(lorActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageLor));
