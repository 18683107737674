import * as personActions from "../../store/actions/personAction";
import ContentTranslator from "../translator/ContentTranslator";
import React from "react";
import { connect } from "react-redux";
import { Button, Col, CustomInput, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

class TableLorProfilesEditAtrribute extends React.Component {
    static displayName = TableLorProfilesEditAtrribute.name;

    constructor(props) {
        super(props);
        this.state = {
            account: null,
            attributes: [],
            lorAttributes: [],
            updating: false,
        };
    }

    componentDidMount() {
        this.setState({ account: this.props.account, attributes: this.props.account ? [...this.props.account.attributes] : [], lorAttributes: this.props.account ? [...this.props.account.lorAttributes] : [] });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.account !== this.props.account) {
            console.log("TableLorProfilesEditAtrribute Account Updated");
            this.setState({ account: this.props.account, attributes: this.props.account ? [...this.props.account.attributes] : [], lorAttributes: this.props.account ? [...this.props.account.lorAttributes] : [] });
        }
    }

    handleAttributeCheckedChange = (attributeId) => {
        let lorAttributes = this.state.lorAttributes;

        if (lorAttributes && lorAttributes.length > 0) {
            if (lorAttributes.find((it) => it.lorAttributeId == attributeId)) {
                var attribute = lorAttributes.find((it) => it.lorAttributeId == attributeId);
                var index = lorAttributes.indexOf(attribute);
                if (index !== -1) {
                    lorAttributes.splice(index, 1);
                }
            } else {
                lorAttributes.push({
                    lorAttributeId: attributeId,
                });
            }
        } else {
            lorAttributes = [
                {
                    lorAttributeId: attributeId,
                },
            ];
        }

        this.setState({ lorAttributes });
    };

    handleSaveEditAttributeClick = () => {
        const { account, lorAttributes } = this.state;

        this.setState({ updating: true });

        this.props.onPerson
            .updatePersonAccountLorAttributes(account.id, {
                lorAttributes: lorAttributes,
            })
            .then(() => {
                if (!this.props.updating) {
                    if (this.props.error) {
                        this.props.generateAlert("danger", this.props.error.errData.Message);
                    } else {
                        this.props.generateAlert("success", "Lor attributes updated");
                        this.props.updateLorAttributes(this.props.lorAttributes);
                    }
                }

                this.setState({ updating: this.props.updating });
            });
    };

    render() {
        const { editedLor } = this.props;
        const lorAttributes = this.state.lorAttributes;

        if (this.props.editAttribute) {
            return (
                <Modal isOpen={this.props.editAttribute} toggle={this.state.updating ? null : this.props.togleEditLorAttribute}>
                    <ModalHeader toggle={this.props.togleEditLorAttribute}>
                        <ContentTranslator page="SfiaProfile" name="EditAttributesTitle" contentText="Edit Attributes" />
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <div style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "500px" }}>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr valign="top">
                                            <td style={{ width: "100%", backgroundColor: editedLor.categoryColor ? editedLor.categoryColor : "#69849c" }} className="td-edit-skill-header">
                                                <ContentTranslator page="Lors" name={editedLor.name} contentText={editedLor.name} />
                                            </td>
                                        </tr>
                                        {this.state.updating ? (
                                            <tr valign="top">
                                                <td style={{ width: "100%", textAlign: "center" }}>
                                                    <Spinner size="lg" animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                        ) : (
                                            <tr valign="top">
                                                <td style={{ width: "100%" }} className="td-edit-skill">
                                                    <Row>
                                                        {editedLor.attributes
                                                            .filter((it) => !it.isHidden)
                                                            .sort((a, b) => a.attribute > b.attribute)
                                                            .map((attribute, index) => {
                                                                return (
                                                                    <Col xs="4" key={index}>
                                                                        <CustomInput
                                                                            key={index}
                                                                            type="checkbox"
                                                                            name={`chk${attribute.id}`}
                                                                            id={`chk${attribute.id}`}
                                                                            checked={lorAttributes && lorAttributes.find((it) => it.lorAttributeId == attribute.id) ? true : false}
                                                                            label={
                                                                                attribute.link && attribute.link != "" && !this.props.loginWithTeams ? (
                                                                                    <a href={attribute.link} target="_blank" rel="noreferrer">
                                                                                        {attribute.attribute}
                                                                                    </a>
                                                                                ) : (
                                                                                    attribute.attribute
                                                                                )
                                                                            }
                                                                            onChange={() => this.handleAttributeCheckedChange(attribute.id)}
                                                                            inline
                                                                            disabled={this.props.managedPeople}
                                                                        />
                                                                    </Col>
                                                                );
                                                            })}
                                                    </Row>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" disabled={this.state.updating} onClick={this.props.togleEditLorAttribute}>
                            <ContentTranslator page="SfiaProfile" name="EditSkillButtonClose" contentText="Close" />
                        </Button>{" "}
                        {!this.props.managedPeople && (
                            <Button color="primary" disabled={this.state.updating} onClick={() => this.handleSaveEditAttributeClick()}>
                                <ContentTranslator page="SfiaProfile" name="EditSkillButtonSave" contentText="Save changes" />
                            </Button>
                        )}
                    </ModalFooter>
                </Modal>
            );
        } else {
            return "";
        }
    }
}

const mapStateToProps = (state) => {
    return {
        updating: state.person.updating,
        error: state.person.error,
        lorAttributes: state.person.lorAttributes,
        managedPeople: state.auth.managedPeople,
        contentTranslations: state.content.contentTranslations,
        loginWithTeams: state.auth.loginWithTeams,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPerson: bindActionCreators(personActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableLorProfilesEditAtrribute);
