import CompanyAPI from "./../../common/api/service/CompanyService";
import * as actionTypes from "./actionTypes";

export const getCompaniesStart = () => {
    return {
        type: actionTypes.GET_COMPANIES_START,
    };
};

export const getCompaniesSuccess = (companies) => {
    return {
        type: actionTypes.GET_COMPANIES_SUCCESS,
        companies,
    };
};

export const getCompaniesFail = (error) => {
    return {
        type: actionTypes.GET_COMPANIES_FAIL,
        error: error,
    };
};

export const getCompanies = (companyType) => {
    return (dispatch) => {
        dispatch(getCompaniesStart());

        return CompanyAPI.getCompanies(companyType)
            .then((res) => {
                dispatch(getCompaniesSuccess(res));
            })
            .catch((err) => {
                dispatch(getCompaniesFail(err));
            });
    };
};

export const getDefaultCompanyStart = () => {
    return {
        type: actionTypes.GET_DEFAULT_COMPANY_START,
    };
};

export const getDefaultCompanySuccess = (defaultCompany) => {
    return {
        type: actionTypes.GET_DEFAULT_COMPANY_SUCCESS,
        defaultCompany,
    };
};

export const getDefaultCompanyFail = (error) => {
    return {
        type: actionTypes.GET_DEFAULT_COMPANY_FAIL,
        error: error,
    };
};

export const getDefaultCompany = () => {
    return (dispatch) => {
        dispatch(getDefaultCompanyStart());

        return CompanyAPI.getDefaultCompany()
            .then((res) => {
                dispatch(getDefaultCompanySuccess(res));
            })
            .catch((err) => {
                dispatch(getDefaultCompanyFail(err));
            });
    };
};

export const getCompanyBrandingLogoStart = () => {
    return {
        type: actionTypes.GET_COMPANY_BRANDING_LOGO_START,
    };
};

export const getCompanyBrandingLogoSuccess = (brandingLogoImage) => {
    return {
        type: actionTypes.GET_COMPANY_BRANDING_LOGO_SUCCESS,
        brandingLogoImage,
    };
};

export const getCompanyBrandingLogoFail = (error) => {
    return {
        type: actionTypes.GET_COMPANY_BRANDING_LOGO_FAIL,
        error: error,
    };
};

export const getCompanyBrandingLogo = () => {
    return (dispatch) => {
        dispatch(getCompanyBrandingLogoStart());

        return CompanyAPI.getCompanyBrandingLogo()
            .then((res) => {
                dispatch(getCompanyBrandingLogoSuccess(res));
            })
            .catch((err) => {
                dispatch(getCompanyBrandingLogoFail(err));
            });
    };
};

export const getCompanyFavIconStart = () => {
    return {
        type: actionTypes.GET_COMPANY_FAVICON_START,
    };
};

export const getCompanyFavIconSuccess = (favIconImage) => {
    return {
        type: actionTypes.GET_COMPANY_FAVICON_SUCCESS,
        favIconImage,
    };
};

export const getCompanyFavIconFail = (error) => {
    return {
        type: actionTypes.GET_COMPANY_FAVICON_FAIL,
        error: error,
    };
};

export const getCompanyFavIcon = () => {
    return (dispatch) => {
        dispatch(getCompanyFavIconStart());

        return CompanyAPI.getCompanyFavIcon()
            .then((res) => {
                dispatch(getCompanyFavIconSuccess(res));
            })
            .catch((err) => {
                dispatch(getCompanyFavIconFail(err));
            });
    };
};

export const createCompanyStart = () => {
    return {
        type: actionTypes.CREATE_COMPANY_START,
    };
};

export const createCompanySuccess = (addedCompany) => {
    return {
        type: actionTypes.CREATE_COMPANY_SUCCESS,
        addedCompany,
    };
};

export const createCompanyFail = (error) => {
    return {
        type: actionTypes.CREATE_COMPANY_FAIL,
        error: error,
    };
};

export const createCompany = (company) => {
    return (dispatch) => {
        dispatch(createCompanyStart());

        return CompanyAPI.addNewCompany(company)
            .then((res) => {
                dispatch(createCompanySuccess(res));
            })
            .catch((err) => {
                dispatch(createCompanyFail(err));
            });
    };
};

export const updateCompanyStart = () => {
    return {
        type: actionTypes.UPDATE_COMPANY_START,
    };
};

export const updateCompanySuccess = (updatedCompany) => {
    return {
        type: actionTypes.UPDATE_COMPANY_SUCCESS,
        updatedCompany,
    };
};

export const updateCompanyFail = (error) => {
    return {
        type: actionTypes.UPDATE_COMPANY_FAIL,
        error: error,
    };
};

export const updateCompany = (id, company) => {
    return (dispatch) => {
        dispatch(updateCompanyStart());

        return CompanyAPI.updateCompany(id, company)
            .then((res) => {
                dispatch(updateCompanySuccess(res));
            })
            .catch((err) => {
                dispatch(updateCompanyFail(err));
            });
    };
};

export const updateCompanyProviderSelection = (id, company) => {
    return (dispatch) => {
        dispatch(updateCompanyStart());

        return CompanyAPI.updateCompanyProviderSelection(id, company)
            .then((res) => {
                dispatch(updateCompanySuccess(res));
            })
            .catch((err) => {
                dispatch(updateCompanyFail(err));
            });
    };
};

export const getSelectedProviderCompaniesStart = () => {
    return {
        type: actionTypes.GET_SELECTED_PROVIDER_COMPANIES_START,
    };
};

export const getSelectedProviderCompaniesSuccess = (companies) => {
    return {
        type: actionTypes.GET_SELECTED_PROVIDER_COMPANIES_SUCCESS,
        companies,
    };
};

export const getSelectedProviderCompaniesFail = (error) => {
    return {
        type: actionTypes.GET_SELECTED_PROVIDER_COMPANIES_FAIL,
        error: error,
    };
};

export const getSelectedProviderCompanies = () => {
    return (dispatch) => {
        dispatch(getSelectedProviderCompaniesStart());

        return CompanyAPI.getSelectedProviderCompanies()
            .then((res) => {
                dispatch(getSelectedProviderCompaniesSuccess(res));
            })
            .catch((err) => {
                dispatch(getSelectedProviderCompaniesFail(err));
            });
    };
};

export const getProviderCompaniesStart = () => {
    return {
        type: actionTypes.GET_PROVIDER_COMPANIES_START,
    };
};

export const getProviderCompaniesSuccess = (companies) => {
    return {
        type: actionTypes.GET_PROVIDER_COMPANIES_SUCCESS,
        companies,
    };
};

export const getProviderCompaniesFail = (error) => {
    return {
        type: actionTypes.GET_PROVIDER_COMPANIES_FAIL,
        error: error,
    };
};

export const getProviderCompanies = () => {
    return (dispatch) => {
        dispatch(getProviderCompaniesStart());

        return CompanyAPI.getProviderCompanies()
            .then((res) => {
                dispatch(getProviderCompaniesSuccess(res));
            })
            .catch((err) => {
                dispatch(getProviderCompaniesFail(err));
            });
    };
};
