import * as jobActions from "../../../store/actions/jobAction";
import ContentTranslator from "../../translator/ContentTranslator";
import React from "react";
import { connect } from "react-redux";
import { Button, Col, CustomInput, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

class ManageJobLorAttribute extends React.Component {
    static displayName = ManageJobLorAttribute.name;

    constructor(props) {
        super(props);
        this.state = {
            job: this.props.job,
            attributes: this.props.lorAttributes ? [...this.props.lorAttributes] : [],
            updating: false,
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.lorAttributes !== this.props.lorAttributes) {
            this.setState({
                attributes: this.props.lorAttributes ? [...this.props.lorAttributes] : [],
            });
        }
    }

    handleAttributeCheckedChange = (attributeId) => {
        let attributes = this.state.attributes;
        if (attributes && attributes.length > 0) {
            if (attributes.find((it) => it.lorAttributeId == attributeId)) {
                var attribute = attributes.find((it) => it.lorAttributeId == attributeId);
                var index = attributes.indexOf(attribute);
                if (index !== -1) {
                    attributes.splice(index, 1);
                }
            } else {
                attributes.push({
                    lorAttributeId: attributeId,
                });
            }
        } else {
            attributes = [
                {
                    lorAttributeId: attributeId,
                },
            ];
        }
        this.setState({ attributes });
    };

    togleEditLorAttribute = () => {
        this.setState({
            attributes: this.props.skillAttributes ? [...this.props.skillAttributes] : [],
        });
        this.props.togleEditLorAttribute();
    };

    handleSaveEditAttributeClick = () => {
        const { job, attributes } = this.state;
        this.setState({ updating: true });
        this.props.onJob.updateJobLorAttributes(job.id, attributes).then(() => {
            if (!this.props.updating) {
                if (this.props.error) {
                    this.props.generateAlert("danger", this.props.error.errData.Message);
                } else {
                    this.props.generateAlert("success", "Lor attributes updated");
                    this.props.updateLorAttributes(this.props.jobLorAttributes);
                }
            }
            this.setState({ updating: this.props.updating });
        });
    };

    render() {
        const { editedLor } = this.props;
        const attributes = this.state.attributes;

        if (this.props.editAttribute) {
            return (
                <Modal isOpen={this.props.editAttribute} toggle={this.state.updating ? null : this.props.togleEditLorAttribute}>
                    <ModalHeader toggle={this.props.togleEditLorAttribute}>Edit Attributes</ModalHeader>
                    <ModalBody>
                        <Form>
                            <div style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "500px" }}>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr valign="top">
                                            <td style={{ width: "100%", backgroundColor: editedLor.categoryColor ? editedLor.categoryColor : "#69849c" }} className="td-edit-skill-header">
                                                <ContentTranslator page="Lors" name={editedLor.name} contentText={editedLor.name} />
                                            </td>
                                        </tr>
                                        {this.state.updating ? (
                                            <tr valign="top">
                                                <td style={{ width: "100%", textAlign: "center" }}>
                                                    <Spinner size="lg" animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                        ) : (
                                            <tr valign="top">
                                                <td style={{ width: "100%" }} className="td-edit-skill">
                                                    <Row>
                                                        {editedLor.attributes
                                                            .sort((a, b) => a.attribute > b.attribute)
                                                            .map((attribute, index) => {
                                                                return (
                                                                    <Col xs="4" key={index}>
                                                                        <CustomInput
                                                                            key={index}
                                                                            type="checkbox"
                                                                            name={`chk${attribute.id}`}
                                                                            id={`chk${attribute.id}`}
                                                                            checked={attributes && attributes.find((it) => it.lorAttributeId == attribute.id) ? true : false}
                                                                            label={
                                                                                attribute.link && attribute.link != "" && !this.props.loginWithTeams ? (
                                                                                    <a href={attribute.link} target="_blank" rel="noreferrer">
                                                                                        {attribute.attribute}
                                                                                    </a>
                                                                                ) : (
                                                                                    attribute.attribute
                                                                                )
                                                                            }
                                                                            onChange={() => this.handleAttributeCheckedChange(attribute.id)}
                                                                            inline
                                                                            disabled={this.props.managedPeople || !this.props.allowEdit}
                                                                        />
                                                                    </Col>
                                                                );
                                                            })}
                                                    </Row>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" disabled={this.state.updating} onClick={this.togleEditLorAttribute}>
                            Close
                        </Button>{" "}
                        {!this.props.managedPeople && this.props.allowEdit && (
                            <Button color="primary" disabled={this.state.updating} onClick={() => this.handleSaveEditAttributeClick()}>
                                Save changes
                            </Button>
                        )}
                    </ModalFooter>
                </Modal>
            );
        } else {
            return "";
        }
    }
}

const mapStateToProps = (state) => {
    return {
        updating: state.job.updating,
        error: state.job.error,
        jobLorAttributes: state.job.jobLorAttributes,
        contentTranslations: state.content.contentTranslations,
        loginWithTeams: state.auth.loginWithTeams,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onJob: bindActionCreators(jobActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageJobLorAttribute);
