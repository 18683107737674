import * as actionTypes from "../actions/actionTypes";
import { updateState } from "../utility";

const initialState = {
    lors: [],
    error: null,
    loading: false,
    exportData: null,
    loadingExport: false,
    isImporting: false,
    isUpdating: false,
};

const getLorsStart = (state) => {
    return updateState(state, { error: null, lors: [], loading: true });
};

const getLorsSuccess = (state, action) => {
    return updateState(state, {
        lors: action.lors,
        error: null,
        loading: false,
    });
};

const getLorsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const updateLorStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateLorSuccess = (state, action) => {
    let lors = state.lors;

    const updatedLor = lors.find((x) => x.id == action.lor.id);
    if (updatedLor) {
        updatedLor.category = action.lor.category;
        updatedLor.categoryColor = action.lor.categoryColor;
        updatedLor.lorColor = action.lor.lorColor;
    }

    return updateState(state, {
        lors: [...lors],
        error: null,
        isUpdating: false,
    });
};

const updateLorFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateLorSetIsHiddenStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateLorSetIsHiddenSuccess = (state, action) => {
    const lors = state.lors;

    const isHidden = action.isHidden;

    const lor = lors.find((it) => it.id == action.id);

    if (lor) {
        lor.isHidden = isHidden;
    }

    return updateState(state, {
        lors: [...lors],
        error: null,
        isUpdating: false,
    });
};

const updateLorSetOptionalSuccess = (state, action) => {
    const lors = state.lors;

    const optional = action.optional;

    const lor = lors.find((it) => it.id == action.id);

    if (lor) {
        lor.optional = optional;
    }

    return updateState(state, {
        lors: [...lors],
        error: null,
        isUpdating: false,
    });
};

const updateLorSetIsHiddenFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const addLorAttributeStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const addLorAttributeSuccess = (state, action) => {
    const lors = state.lors;

    const addedAttribute = action.addedAttribute;
    const lor = lors.find((it) => it.id == action.lorId);
    if (lor) {
        if (lor.attributes) {
            lor.attributes.push({
                id: addedAttribute.id,
                assessmentId: addedAttribute.assessmentId,
                attribute: addedAttribute.attribute,
                link: addedAttribute.link,
                isHidden: addedAttribute.isHidden,
            });
        } else {
            lor.attributes = [
                {
                    id: addedAttribute.id,
                    assessmentId: addedAttribute.assessmentId,
                    attribute: addedAttribute.attribute,
                    link: addedAttribute.link,
                    isHidden: addedAttribute.isHidden,
                },
            ];
        }
    }

    return updateState(state, {
        lors: [...lors],
        error: null,
        isUpdating: false,
    });
};

const addLorAttributeFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateLorAttributeStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateLorAttributeSuccess = (state, action) => {
    const lors = state.lors;

    const updatedAttribute = action.updatedAttribute;
    const lor = lors.find((it) => it.id == action.lorId);
    if (lor) {
        const attribute = lor.attributes && lor.attributes.find((it) => it.id == updatedAttribute.id);
        if (attribute) {
            attribute.attribute = updatedAttribute.attribute;
            attribute.link = updatedAttribute.link;
            attribute.isHidden = updatedAttribute.isHidden;
        }
    }

    return updateState(state, {
        lors: [...lors],
        error: null,
        isUpdating: false,
    });
};

const updateLorAttributeFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deleteLorAttributeStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deleteLorAttributeSuccess = (state, action) => {
    const lors = state.lors;

    const deletedAttributeId = action.deletedAttributeId;
    const lor = lors.find((it) => it.id == action.lorId);
    if (lor) {
        const attribute = lor.attributes && lor.attributes.find((it) => it.id == deletedAttributeId);
        if (attribute) {
            var index = lor.attributes.indexOf(attribute);
            if (index !== -1) {
                lor.attributes.splice(index, 1);
            }
        }
    }

    return updateState(state, {
        lors: [...lors],
        error: null,
        isUpdating: false,
    });
};

const deleteLorAttributeFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const exportLorAttributesDataExcelStart = (state) => {
    return updateState(state, { error: null, loadingExport: true });
};

const exportLorAttributesDataExcelSuccess = (state, action) => {
    return updateState(state, {
        exportData: action.exportData,
        error: null,
        loadingExport: false,
    });
};

const exportLorAttributesDataExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loadingExport: false,
    });
};

const importLorAttributesDataExcelStart = (state) => {
    return updateState(state, { error: null, isImporting: true, message: null });
};

const importLorAttributesDataExcelSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        isImporting: false,
    });
};

const importLorAttributesDataExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isImporting: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_LORS_START:
            return getLorsStart(state);
        case actionTypes.GET_LORS_SUCCESS:
            return getLorsSuccess(state, action);
        case actionTypes.GET_LORS_FAIL:
            return getLorsFail(state, action);

        case actionTypes.UPDATE_LOR_START:
            return updateLorStart(state);
        case actionTypes.UPDATE_LOR_SUCCESS:
            return updateLorSuccess(state, action);
        case actionTypes.UPDATE_LOR_FAIL:
            return updateLorFail(state, action);

        case actionTypes.UPDATE_LOR_SET_ISHIDDEN_START:
            return updateLorSetIsHiddenStart(state);
        case actionTypes.UPDATE_LOR_SET_ISHIDDEN_SUCCESS:
            return updateLorSetIsHiddenSuccess(state, action);
        case actionTypes.UPDATE_LOR_SET_OPTIONAL_SUCCESS:
            return updateLorSetOptionalSuccess(state, action);
        case actionTypes.UPDATE_LOR_SET_ISHIDDEN_FAIL:
            return updateLorSetIsHiddenFail(state, action);

        case actionTypes.ADD_LOR_ATTRIBUTE_DATA_START:
            return addLorAttributeStart(state);
        case actionTypes.ADD_LOR_ATTRIBUTE_DATA_SUCCESS:
            return addLorAttributeSuccess(state, action);
        case actionTypes.ADD_LOR_ATTRIBUTE_DATA_FAIL:
            return addLorAttributeFail(state, action);

        case actionTypes.UPDATE_LOR_ATTRIBUTE_DATA_START:
            return updateLorAttributeStart(state);
        case actionTypes.UPDATE_LOR_ATTRIBUTE_DATA_SUCCESS:
            return updateLorAttributeSuccess(state, action);
        case actionTypes.UPDATE_LOR_ATTRIBUTE_DATA_FAIL:
            return updateLorAttributeFail(state, action);

        case actionTypes.DELETE_LOR_ATTRIBUTE_DATA_START:
            return deleteLorAttributeStart(state);
        case actionTypes.DELETE_LOR_ATTRIBUTE_DATA_SUCCESS:
            return deleteLorAttributeSuccess(state, action);
        case actionTypes.DELETE_LOR_ATTRIBUTE_DATA_FAIL:
            return deleteLorAttributeFail(state, action);

        case actionTypes.EXPORT_LOR_ATTRIBUTES_DATA_START:
            return exportLorAttributesDataExcelStart(state);
        case actionTypes.EXPORT_LOR_ATTRIBUTES_DATA_SUCCESS:
            return exportLorAttributesDataExcelSuccess(state, action);
        case actionTypes.EXPORT_LOR_ATTRIBUTES_DATA_FAIL:
            return exportLorAttributesDataExcelFail(state, action);

        case actionTypes.IMPORT_LOR_ATTRIBUTES_DATA_START:
            return importLorAttributesDataExcelStart(state);
        case actionTypes.IMPORT_LOR_ATTRIBUTES_DATA_SUCCESS:
            return importLorAttributesDataExcelSuccess(state, action);
        case actionTypes.IMPORT_LOR_ATTRIBUTES_DATA_FAIL:
            return importLorAttributesDataExcelFail(state, action);

        default:
            return state;
    }
};

export default reducer;
