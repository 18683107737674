import ErrorHandler from "./../errorResponseHandler";
import Instance from "./AxiosInstance";

export const axios = Instance.apiInstance();

class EvidenceAPI {
    static getEvidencesByAccountId() {
        return axios
            .get(`/evidences/byaccountId`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static getEvidencesByManagedPeopleAccountId(email) {
        return axios
            .get(`/evidences/byManagedPeopleAccountId?email=${email}`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static downloadEvidence(id) {
        return axios
            .get(`/evidences/download/${id}`,
                {
                    responseType: 'blob'
                })
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static downloadManagedPeopleEvidence(email, id) {
        return axios
            .get(`/evidences/downloadManagedPeopleEvidence?email=${email}&id=${id}`,
                {
                    responseType: 'blob'
                })
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static getEvidenceTypes() {
        return axios
            .get(`/evidences/evidenceTypes`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static addEvidence(evidence) {
        return axios
            .post(`/evidences`, evidence
            )
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static addEvidences(evidences) {
        return axios
            .post(`/evidences/addEvidences`, evidences
            )
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static addManagedPeopleEvidence(evidence) {
        return axios
            .post(`/evidences/managedPeopleEvidence`, evidence
            )
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static updateEvidence(evidenceId, evidence) {
        return axios
            .put(`/evidences/${evidenceId}`, evidence
            )
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static updateManagedPeopleEvidence(evidenceId, evidence) {
        return axios
            .put(`/evidences/managedPeopleEvidence/${evidenceId}`, evidence
            )
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static deleteEvidence(evidenceId) {
        return axios
            .delete(`/evidences/${evidenceId}`
            )
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static deleteManagedPeopleEvidence(evidenceId, personEmail) {
        return axios
            .delete(`/evidences/managedPeopleEvidence/${evidenceId}?personEmail=${personEmail}`
            )
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static getMyBadges() {
      return axios
        .get(`/evidences/mybadges`)
        .then((res) => {
          const response = res.data;
          return response;
        })
        .catch((err) => {
          throw ErrorHandler(err);
        });
    }
}

export default EvidenceAPI;