import LorAPI from "./../../common/api/service/LorService";
import * as actionTypes from "./actionTypes";

export const getLorsStart = () => {
    return {
        type: actionTypes.GET_LORS_START,
    };
};

export const getLorsSuccess = (lors) => {
    return {
        type: actionTypes.GET_LORS_SUCCESS,
        lors,
    };
};

export const getLorsFail = (error) => {
    return {
        type: actionTypes.GET_LORS_FAIL,
        error: error,
    };
};

export const getLors = () => {
    return (dispatch) => {
        dispatch(getLorsStart());

        return LorAPI.getLors()
            .then((res) => {
                if (res) {
                    dispatch(getLorsSuccess(res));
                } else {
                    dispatch(getLorsFail(res));
                }
            })
            .catch((err) => {
                dispatch(getLorsFail(err));
            });
    };
};

export const updateLorStart = () => {
    return {
        type: actionTypes.UPDATE_LOR_START,
    };
};

export const updateLorSuccess = (lor) => {
    return {
        type: actionTypes.UPDATE_LOR_SUCCESS,
        lor,
    };
};

export const updateLorFail = (error) => {
    return {
        type: actionTypes.UPDATE_LOR_FAIL,
        error: error,
    };
};

export const updateLor = (id, lor) => {
    return (dispatch) => {
        dispatch(updateLorStart());

        return LorAPI.updateLor(id, lor)
            .then((res) => {
                if (res) {
                    dispatch(updateLorSuccess(res));
                } else {
                    dispatch(updateLorFail(res));
                }
            })
            .catch((err) => {
                dispatch(updateLorFail(err));
            });
    };
};

export const updateLorSetIsHiddenStart = () => {
    return {
        type: actionTypes.UPDATE_LOR_SET_ISHIDDEN_START,
    };
};

export const updateLorSetIsHiddenSuccess = (id, isHidden) => {
    return {
        type: actionTypes.UPDATE_LOR_SET_ISHIDDEN_SUCCESS,
        id,
        isHidden,
    };
};

export const updateLorSetOptionalSuccess = (id, optional) => {
    return {
        type: actionTypes.UPDATE_LOR_SET_OPTIONAL_SUCCESS,
        id,
        optional,
    };
};

export const updateLorSetIsHiddenFail = (error) => {
    return {
        type: actionTypes.UPDATE_LOR_SET_ISHIDDEN_FAIL,
        error: error,
    };
};

export const updateLorSetIsHidden = (id, isHidden) => {
    return (dispatch) => {
        dispatch(updateLorSetIsHiddenStart());

        return LorAPI.updateLorIsHidden(id, isHidden)
            .then((res) => {
                if (res) {
                    dispatch(updateLorSetIsHiddenSuccess(id, isHidden));
                } else {
                    dispatch(updateLorSetIsHiddenFail(res));
                }
            })
            .catch((err) => {
                dispatch(updateLorSetIsHiddenFail(err));
            });
    };
};

export const updateLorSetOptional = (id, optional) => {
    return (dispatch) => {
        dispatch(updateLorSetIsHiddenStart());

        return LorAPI.updateLorOptional(id, optional)
            .then((res) => {
                if (res) {
                    dispatch(updateLorSetOptionalSuccess(id, optional));
                } else {
                    dispatch(updateLorSetIsHiddenFail(res));
                }
            })
            .catch((err) => {
                dispatch(updateLorSetIsHiddenFail(err));
            });
    };
};

export const addLorAttributeStart = () => {
    return {
        type: actionTypes.ADD_LOR_ATTRIBUTE_DATA_START,
    };
};

export const addLorAttributeSuccess = (lorId, addedAttribute) => {
    return {
        type: actionTypes.ADD_LOR_ATTRIBUTE_DATA_SUCCESS,
        lorId,
        addedAttribute,
    };
};

export const addLorAttributeFail = (error) => {
    return {
        type: actionTypes.ADD_LOR_ATTRIBUTE_DATA_FAIL,
        error: error,
    };
};

export const addLorAttribute = (lorId, attribute) => {
    return (dispatch) => {
        dispatch(addLorAttributeStart());

        return LorAPI.addLorAttribute(lorId, attribute)
            .then((res) => {
                if (res) {
                    dispatch(addLorAttributeSuccess(lorId, res));
                } else {
                    dispatch(addLorAttributeFail(res));
                }
            })
            .catch((err) => {
                dispatch(addLorAttributeFail(err));
            });
    };
};

export const updateLorAttributeStart = () => {
    return {
        type: actionTypes.UPDATE_LOR_ATTRIBUTE_DATA_START,
    };
};

export const updateLorAttributeSuccess = (lorId, updatedAttribute) => {
    return {
        type: actionTypes.UPDATE_LOR_ATTRIBUTE_DATA_SUCCESS,
        lorId,
        updatedAttribute,
    };
};

export const updateLorAttributeFail = (error) => {
    return {
        type: actionTypes.UPDATE_LOR_ATTRIBUTE_DATA_FAIL,
        error: error,
    };
};

export const updateLorAttribute = (lorId, attributeId, attribute) => {
    return (dispatch) => {
        dispatch(updateLorAttributeStart());

        return LorAPI.updateLorAttribute(lorId, attributeId, attribute)
            .then((res) => {
                if (res) {
                    dispatch(updateLorAttributeSuccess(lorId, res));
                } else {
                    dispatch(updateLorAttributeFail(res));
                }
            })
            .catch((err) => {
                dispatch(updateLorAttributeFail(err));
            });
    };
};

export const deleteLorAttributeStart = () => {
    return {
        type: actionTypes.DELETE_LOR_ATTRIBUTE_DATA_START,
    };
};

export const deleteLorAttributeSuccess = (lorId, deletedAttributeId) => {
    return {
        type: actionTypes.DELETE_LOR_ATTRIBUTE_DATA_SUCCESS,
        lorId,
        deletedAttributeId,
    };
};

export const deleteLorAttributeFail = (error) => {
    return {
        type: actionTypes.DELETE_LOR_ATTRIBUTE_DATA_FAIL,
        error: error,
    };
};

export const deleteLorAttribute = (lorId, attributeId) => {
    return (dispatch) => {
        dispatch(deleteLorAttributeStart());

        return LorAPI.deleteLorAttribute(lorId, attributeId)
            .then((res) => {
                if (res) {
                    dispatch(deleteLorAttributeSuccess(lorId, attributeId));
                } else {
                    dispatch(deleteLorAttributeFail(res));
                }
            })
            .catch((err) => {
                dispatch(deleteLorAttributeFail(err));
            });
    };
};

export const exportLorAttributesDataExcelStart = () => {
    return {
        type: actionTypes.EXPORT_LOR_ATTRIBUTES_DATA_START,
    };
};

export const exportLorAttributesDataExcelSuccess = (exportData) => {
    return {
        type: actionTypes.EXPORT_LOR_ATTRIBUTES_DATA_SUCCESS,
        exportData,
    };
};

export const exportLorAttributesDataExcelFail = (error) => {
    return {
        type: actionTypes.EXPORT_LOR_ATTRIBUTES_DATA_FAIL,
        error: error,
    };
};

export const exportLorAttributesDataExcel = () => {
    return (dispatch) => {
        dispatch(exportLorAttributesDataExcelStart());

        return LorAPI.exportLorAttributesDataExcel()
            .then((res) => {
                dispatch(exportLorAttributesDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportLorAttributesDataExcelFail(err));
            });
    };
};

export const exportLorAttributesDataExcelTemplate = () => {
    return (dispatch) => {
        dispatch(exportLorAttributesDataExcelStart());

        return LorAPI.exportLorAttributesDataExcelTemplate()
            .then((res) => {
                dispatch(exportLorAttributesDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportLorAttributesDataExcelFail(err));
            });
    };
};

export const importLorAttributesDataExcelStart = () => {
    return {
        type: actionTypes.IMPORT_LOR_ATTRIBUTES_DATA_START,
    };
};

export const importLorAttributesDataExcelSuccess = () => {
    return {
        type: actionTypes.IMPORT_LOR_ATTRIBUTES_DATA_SUCCESS,
    };
};

export const importLorAttributesDataExcelFail = (error) => {
    return {
        type: actionTypes.IMPORT_LOR_ATTRIBUTES_DATA_FAIL,
        error: error,
    };
};

export const importLorAttributesDataExcel = (attributesData) => {
    return (dispatch) => {
        dispatch(importLorAttributesDataExcelStart());

        return LorAPI.importLorAttributesDataExcel(attributesData)
            .then((res) => {
                dispatch(importLorAttributesDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(importLorAttributesDataExcelFail(err));
            });
    };
};