import ErrorHandler from "./../errorResponseHandler";
import Instance from "./AxiosInstance";

export const axios = Instance.apiInstance();

class CompanyAPI {
    static getCompanies(companyType) {
        return axios
            .get(`/companies?${companyType && `companyType=${companyType}`}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getDefaultCompany() {
        return axios
            .get(`/companies/default`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getCompanyBrandingLogo() {
        return axios
            .get(`/companies/brandinglogobase64`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static getSelectedProviderCompanies() {
        return axios
            .get(`/companies/selectedProviderCompanies`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getProviderCompanies() {
        return axios
            .get(`/companies/providers`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static addNewCompany(company) {
        return axios
            .post(`/companies`, company)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateCompany(id, company) {
        return axios
            .put(`/companies/${id}`, company)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateCompanyProviderSelection(id, company) {
        return axios
            .put(`/companies/${id}/providerselection`, company)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getCompanyFavIcon() {
        return axios
            .get(`/companies/faviconbase64`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }
}

export default CompanyAPI;
