import * as actionTypes from "./adminActionTypes";

import AdminCompanyAPI from "./../../../common/api/adminservice/AdminCompanyService";

export const getCompanyBrandingLogoStart = () => {
    return {
        type: actionTypes.GET_ADMIN_COMPANY_BRANDING_LOGO_START
    };
};

export const getCompanyBrandingLogoSuccess = () => {
    return {
        type: actionTypes.GET_ADMIN_COMPANY_BRANDING_LOGO_SUCCESS,
    };
};

export const getCompanyBrandingLogoFail = error => {
    return {
        type: actionTypes.GET_ADMIN_COMPANY_BRANDING_LOGO_FAIL,
        error: error,
    };
};

export const getCompanyBrandingLogo = () => {
    return dispatch => {
        dispatch(getCompanyBrandingLogoStart());

        return AdminCompanyAPI.getBrandingLogo()
            .then(res => {
                dispatch(getCompanyBrandingLogoSuccess(res));
            })
            .catch(err => {
                dispatch(getCompanyBrandingLogoFail(err));
            });
    };
};

export const updateCompanyBrandingLogoStart = () => {
    return {
        type: actionTypes.UPDATE_COMPANY_BRANDING_LOGO_START
    };
};

export const updateCompanyBrandingLogoSuccess = () => {
    return {
        type: actionTypes.UPDATE_COMPANY_BRANDING_LOGO_SUCCESS,
    };
};

export const updateCompanyBrandingLogoFail = error => {
    return {
        type: actionTypes.UPDATE_COMPANY_BRANDING_LOGO_FAIL,
        error: error,
    };
};

export const updateCompanyBrandingLogo = (brandingLogo) => {
    return dispatch => {
        dispatch(updateCompanyBrandingLogoStart());

        return AdminCompanyAPI.updateBrandingLogo(brandingLogo)
            .then(res => {
                dispatch(updateCompanyBrandingLogoSuccess(res));
            })
            .catch(err => {
                dispatch(updateCompanyBrandingLogoFail(err));
            });
    };
};

export const deleteCompanyBrandingLogo = () => {
    return dispatch => {
        dispatch(updateCompanyBrandingLogoStart());

        return AdminCompanyAPI.deleteBrandingLogo()
            .then(res => {
                dispatch(updateCompanyBrandingLogoSuccess(res));
            })
            .catch(err => {
                dispatch(updateCompanyBrandingLogoFail(err));
            });
    };
};

export const getCompanyFavIconStart = () => {
    return {
        type: actionTypes.GET_ADMIN_COMPANY_FAVICON_START
    };
};

export const getCompanyFavIconSuccess = () => {
    return {
        type: actionTypes.GET_ADMIN_COMPANY_FAVICON_SUCCESS,
    };
};

export const getCompanyFavIconFail = error => {
    return {
        type: actionTypes.GET_ADMIN_COMPANY_FAVICON_FAIL,
        error: error,
    };
};

export const getCompanyFavIcon = () => {
    return dispatch => {
        dispatch(getCompanyFavIconStart());

        return AdminCompanyAPI.getFavIcon()
            .then(res => {
                dispatch(getCompanyFavIconSuccess(res));
            })
            .catch(err => {
                dispatch(getCompanyFavIconFail(err));
            });
    };
};

export const updateCompanyFavIconStart = () => {
    return {
        type: actionTypes.UPDATE_COMPANY_FAVICON_START
    };
};

export const updateCompanyFavIconSuccess = () => {
    return {
        type: actionTypes.UPDATE_COMPANY_FAVICON_SUCCESS,
    };
};

export const updateCompanyFavIconFail = error => {
    return {
        type: actionTypes.UPDATE_COMPANY_FAVICON_FAIL,
        error: error,
    };
};

export const updateCompanyFavIcon = (favIcon) => {
    return dispatch => {
        dispatch(updateCompanyFavIconStart());

        return AdminCompanyAPI.updateFavIcon(favIcon)
            .then(res => {
                dispatch(updateCompanyFavIconSuccess(res));
            })
            .catch(err => {
                dispatch(updateCompanyFavIconFail(err));
            });
    };
};

export const deleteCompanyFavIcon = () => {
    return dispatch => {
        dispatch(updateCompanyFavIconStart());

        return AdminCompanyAPI.deleteFavIcon()
            .then(res => {
                dispatch(updateCompanyFavIconSuccess(res));
            })
            .catch(err => {
                dispatch(updateCompanyFavIconFail(err));
            });
    };
};