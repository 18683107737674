import * as authActions from "../../../store/actions/authAction";
import * as jobActions from "../../../store/actions/jobAction";
import * as adminCategoryActions from "../../../store/admin/actions/adminCategoryActions";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import ExtraFrameworkBulkUpload from "./ExtraFrameworkBulkUpload";
import ManageExtraFrameworkCapabilities from "./ManageExtraFrameworkCapabilities";
import ManageExtraFrameworkCategories from "./ManageExtraFrameworkCategories";
import ManageExtraFrameworkLevels from "./ManageExtraFrameworkLevels";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

const rowStyle = {
    fontSize: "12px",
};

class ManageExtraFramework extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            extraFrameworkCapabilities: [],
            extraFrameworkLevels: [],
            extraFrameworkProvided: false,
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
    }

    componentDidMount() {
        this.props.onAuth.authCheckState().then(() => {
            Promise.all([this.props.onAdminCategory.getExtraFrameworkCapabilities(), this.props.onAdminCategory.getExtraFrameworkLevels()]).then(() => {
                this.setState({
                    extraFrameworkCapabilities: this.props.extraFrameworkCapabilities,
                    extraFrameworkLevels: this.props.extraFrameworkLevels,
                    extraFrameworkProvided: this.props.extraFrameworkLevels && this.props.extraFrameworkLevels.length > 0,
                });

                this.setState({ loading: this.props.loading || this.props.levelLoading || this.props.capabilityLoading });
            });
        });
    }

    componentWillUnmount() {}

    handleRefreshCapabilities = () => {
        this.props.onAdminCategory.getExtraFrameworkCapabilities().then(() => {
            this.setState({
                extraFrameworkCapabilities: this.props.extraFrameworkCapabilities,
            });
        });
    };

    handleRefreshLevels = () => {
        this.props.onAdminCategory.getExtraFrameworkLevels().then(() => {
            this.setState({
                extraFrameworkLevels: this.props.extraFrameworkLevels,
            });
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.extraFrameworkCapabilities !== this.props.extraFrameworkCapabilities) {
            this.setState({
                extraFrameworkCapabilities: this.props.extraFrameworkCapabilities,
            });
        }

        if (prevProps.extraFrameworkLevels !== this.props.extraFrameworkLevels) {
            this.setState({
                extraFrameworkLevels: this.props.extraFrameworkLevels,
            });
        }
    }

    pad2 = (n) => {
        return (n < 10 ? "0" : "") + n;
    };

    handleExportExtraFrameworks = () => {
        this.props.onAdminCategory.exportExtraFrameworksDataExcel().then(() => {
            if (!this.props.loadingExport && !this.props.categoryError) {
                if (this.props.exportData) {
                    var today = new Date();
                    var strToday = today.getFullYear() + this.pad2(today.getMonth() + 1) + this.pad2(today.getDate()) + "_" + this.pad2(today.getHours()) + this.pad2(today.getMinutes()) + this.pad2(today.getSeconds());
                    FileDownload(this.props.exportData, `ExportExtraFrameworks_${strToday}.xlsx`);
                }
            }
        });
    };

    handleImportExtraFrameworks = () => {
        this.setState({ showDialogImportTs: new Date() });
    };

    handleImportSuccess = () => {
        this.handleRefreshCapabilities();
        this.handleRefreshLevels();
        this.setState({ forceRefreshExtraFrameworksTS: new Date() });

        if (this.state.extraFrameworkProvided) {
            this.props.onJob.recalculateJobMatches();
        } else {
            this.setState({ extraFrameworkProvided: true });
        }
    };

    render() {
        const pageAssignments = this.props.pageAssignments;
        const pageAssignment = pageAssignments.find((it) => it.pageName == "Manage Extra Frameworks");

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <ExtraFrameworkBulkUpload handleImportSuccess={this.handleImportSuccess} showDialog={this.state.showDialogImportTs} generateAlert={this.generateAlert}></ExtraFrameworkBulkUpload>
                        <div style={{ marginBottom: "10px" }}>
                            <Row>
                                <Col md="12">
                                    <React.Fragment>
                                        {pageAssignment && pageAssignment.operations.find((it) => it.operation == "Export" && it.allow == true) && (
                                            <React.Fragment>
                                                <Button
                                                    color="primary"
                                                    onClick={this.handleExportExtraFrameworks}
                                                    style={{
                                                        fontSize: "16px",
                                                        height: "40px",
                                                    }}
                                                    disabled={this.props.categoryLoading || this.props.loadingPrint}
                                                >
                                                    <FontAwesomeIcon icon={faFileExcel} /> Export
                                                </Button>
                                                &nbsp;&nbsp;
                                            </React.Fragment>
                                        )}
                                        {pageAssignment && pageAssignment.operations.find((it) => it.operation == "Import" && it.allow == true) && (
                                            <Button
                                                color="primary"
                                                onClick={this.handleImportExtraFrameworks}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                }}
                                                disabled={this.props.categoryLoading}
                                            >
                                                <FontAwesomeIcon icon={faFileExcel} /> Import
                                            </Button>
                                        )}
                                    </React.Fragment>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col sm="6">
                                <ManageExtraFrameworkCapabilities pageAssignment={pageAssignment} handleRefreshCapabilities={this.handleRefreshCapabilities} generateAlert={this.generateAlert} extraFrameworkCapabilities={this.state.extraFrameworkCapabilities}></ManageExtraFrameworkCapabilities>
                            </Col>
                            <Col sm="6">
                                <ManageExtraFrameworkLevels pageAssignment={pageAssignment} handleRefreshLevels={this.handleRefreshLevels} generateAlert={this.generateAlert} extraFrameworkLevels={this.state.extraFrameworkLevels}></ManageExtraFrameworkLevels>
                            </Col>
                        </Row>
                        <div style={{ height: "40px" }}>&nbsp;</div>
                        <ManageExtraFrameworkCategories pageAssignment={pageAssignment} generateAlert={this.generateAlert} extraFrameworkLevels={this.state.extraFrameworkLevels} forceRefreshExtraFrameworks={this.state.forceRefreshExtraFrameworksTS}></ManageExtraFrameworkCategories>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        authPersonId: state.auth.personId,

        categoryLoading: state.adminCategory.categoryLoading,
        levelLoading: state.adminCategory.levelLoading,
        capabilityLoading: state.adminCategory.capabilityLoading,
        categoryError: state.adminCategory.error,
        extraFrameworkCapabilities: state.adminCategory.extraFrameworkCapabilities,
        extraFrameworkLevels: state.adminCategory.extraFrameworkLevels,
        categories: state.adminCategory.categories,

        exportData: state.adminCategory.exportData,
        loadingExport: state.adminCategory.loadingExport,

        pageAssignments: state.adminPerson.pageAssignments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onAdminCategory: bindActionCreators(adminCategoryActions, dispatch),
        onJob: bindActionCreators(jobActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageExtraFramework));
