import ErrorHandler from "./../errorResponseHandler";
import Instance from "./AxiosInstance";

export const axios = Instance.apiInstance();

class LorAPI {
    static getLors() {
        return axios
            .get(`/lors`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateLor(id, lor) {
        return axios
            .put(`/lors/${id}`, lor)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateLorIsHidden(id, isHidden) {
        return axios
            .post(`/lors/${id}/updateIsHidden?isHidden=${isHidden ? true : false}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateLorOptional(id, optional) {
        return axios
            .post(`/lors/${id}/updateOptional?optional=${optional ? true : false}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static addLorAttribute(lorId, attribute) {
        return axios
            .post(`/lors/${lorId}/attributes`, attribute)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateLorAttribute(lorId, attributeId, attribute) {
        return axios
            .put(`/lors/${lorId}/attributes/${attributeId}`, attribute)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteLorAttribute(lorId, attributeId) {
        return axios
            .delete(`/lors/${lorId}/attributes/${attributeId}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static exportLorAttributesDataExcel() {
        return axios
            .get(`/lors/exportlorattributes`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static exportLorAttributesDataExcelTemplate() {
        return axios
            .get(`/lors/exportlorattributestemplate`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static importLorAttributesDataExcel(attributesData) {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        const body = new FormData();
        body.append("ContentDisposition", "attachment");
        body.append("ContentType", "multipart/form-data");

        attributesData.forEach((file) => {
            body.append("attributesData", file);
        });

        return axios
            .post(`/lors/importlorattributes`, body, config)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default LorAPI;
