import * as actionTypes from "../actions/actionTypes";
import { updateState } from "../utility";

const initialState = {
    error: null,
    loading: false,
    evidenceTypes: [],
    evidences: [],
    evidenceFile: null,
    updateInProgress: false,
    updatedEvidence: null,
};

const getEvidenceTypesStart = (state) => {
    return updateState(state, { error: null, evidenceTypes: [], loading: true });
};

const getEvidenceTypesSuccess = (state, action) => {
    const evidenceTypes = action.evidenceTypes;
    return updateState(state, {
        evidenceTypes: evidenceTypes,
        error: null,
        loading: false,
    });
};

const getEvidenceTypesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const addEvidenceStart = (state) => {
    return updateState(state, { error: null, updatedEvidence: null, updateInProgress: true });
};

const addEvidenceSuccess = (state, action) => {
    return updateState(state, {
        updatedEvidence: action.evidence,
        error: null,
        updateInProgress: false,
    });
};

const addEvidenceFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        updateInProgress: false,
    });
};

const addEvidencesStart = (state) => {
    return updateState(state, { error: null, updatedEvidences: null, updateInProgress: true });
};

const addEvidencesSuccess = (state, action) => {
    return updateState(state, {
        updatedEvidences: [...action.evidences],
        error: null,
        updateInProgress: false,
    });
};

const addEvidencesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        updateInProgress: false,
    });
};

const updateEvidenceStart = (state) => {
    return updateState(state, { error: null, updatedEvidence: null, updateInProgress: true });
};

const updateEvidenceSuccess = (state, action) => {
    return updateState(state, {
        updatedEvidence: action.evidence,
        error: null,
        updateInProgress: false,
    });
};

const updateEvidenceFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        updateInProgress: false,
    });
};

const deleteEvidenceStart = (state) => {
    return updateState(state, { error: null, updateInProgress: true });
};

const deleteEvidenceSuccess = (state) => {
    return updateState(state, {
        error: null,
        updateInProgress: false,
    });
};

const deleteEvidenceFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        updateInProgress: false,
    });
};

const getEvidencesStart = (state) => {
    return updateState(state, { error: null, evidences: [], loading: true });
};

const getEvidencesSuccess = (state, action) => {
    return updateState(state, {
        evidences: action.evidences,
        error: null,
        loading: false,
    });
};

const getEvidencesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const downloadEvidenceByIdStart = (state) => {
    return updateState(state, { error: null, evidenceFile: null, loading: true });
};

const downloadEvidenceByIdSuccess = (state, action) => {
    return updateState(state, {
        evidenceFile: action.evidenceFile,
        error: null,
        loading: false,
    });
};

const downloadEvidenceByIdFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_EVIDENCE_TYPES_START:
            return getEvidenceTypesStart(state);
        case actionTypes.GET_EVIDENCE_TYPES_SUCCESS:
            return getEvidenceTypesSuccess(state, action);
        case actionTypes.GET_EVIDENCE_TYPES_FAIL:
            return getEvidenceTypesFail(state, action);

        case actionTypes.GET_EVIDENCES_BYACCOUNTID_START:
        case actionTypes.GET_EVIDENCES_BY_MANAGE_PEOPLE_ACCOUNTID_START:
            return getEvidencesStart(state);
        case actionTypes.GET_EVIDENCES_BYACCOUNTID_SUCCESS:
        case actionTypes.GET_EVIDENCES_BY_MANAGE_PEOPLE_ACCOUNTID_SUCCESS:
            return getEvidencesSuccess(state, action);
        case actionTypes.GET_EVIDENCES_BYACCOUNTID_FAIL:
        case actionTypes.GET_EVIDENCES_BY_MANAGE_PEOPLE_ACCOUNTID_FAIL:
            return getEvidencesFail(state, action);

        case actionTypes.ADD_EVIDENCE_START:
        case actionTypes.ADD_MANAGED_PEOPLE_EVIDENCE_START:
            return addEvidenceStart(state);
        case actionTypes.ADD_EVIDENCE_SUCCESS:
        case actionTypes.ADD_MANAGED_PEOPLE_EVIDENCE_SUCCESS:
            return addEvidenceSuccess(state, action);
        case actionTypes.ADD_EVIDENCE_FAIL:
        case actionTypes.ADD_MANAGED_PEOPLE_EVIDENCE_FAIL:
            return addEvidenceFail(state, action);

        case actionTypes.ADD_EVIDENCES_START:
            return addEvidencesStart(state);
        case actionTypes.ADD_EVIDENCES_SUCCESS:
            return addEvidencesSuccess(state, action);
        case actionTypes.ADD_EVIDENCES_FAIL:
            return addEvidencesFail(state, action);

        case actionTypes.UPDATE_EVIDENCE_START:
        case actionTypes.UPDATE_MANAGED_PEOPLE_EVIDENCE_START:
            return updateEvidenceStart(state);
        case actionTypes.UPDATE_EVIDENCE_SUCCESS:
        case actionTypes.UPDATE_MANAGED_PEOPLE_EVIDENCE_SUCCESS:
            return updateEvidenceSuccess(state, action);
        case actionTypes.UPDATE_EVIDENCE_FAIL:
        case actionTypes.UPDATE_MANAGED_PEOPLE_EVIDENCE_FAIL:
            return updateEvidenceFail(state, action);
        case actionTypes.DELETE_EVIDENCE_START:
        case actionTypes.DELETE_MANAGED_PEOPLE_EVIDENCE_START:
            return deleteEvidenceStart(state);
        case actionTypes.DELETE_EVIDENCE_SUCCESS:
        case actionTypes.DELETE_MANAGED_PEOPLE_EVIDENCE_SUCCESS:
            return deleteEvidenceSuccess(state);
        case actionTypes.DELETE_EVIDENCE_FAIL:
        case actionTypes.DELETE_MANAGED_PEOPLE_EVIDENCE_FAIL:
            return deleteEvidenceFail(state, action);

        case actionTypes.DOWNLOAD_EVIDENCE_BYID_START:
        case actionTypes.DOWNLOAD_MANAGED_PEOPLE_EVIDENCE_BYID_START:
            return downloadEvidenceByIdStart(state);
        case actionTypes.DOWNLOAD_EVIDENCE_BYID_SUCCESS:
        case actionTypes.DOWNLOAD_MANAGED_PEOPLE_EVIDENCE_BYID_SUCCESS:
            return downloadEvidenceByIdSuccess(state, action);
        case actionTypes.DOWNLOAD_EVIDENCE_BYID_FAIL:
        case actionTypes.DOWNLOAD_MANAGED_PEOPLE_EVIDENCE_BYID_FAIL:
            return downloadEvidenceByIdFail(state, action);
        default:
            return state;
    }
};

export default reducer;
