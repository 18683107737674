import EvidenceAPI from "./../../common/api/service/EvidenceService";
import * as actionTypes from "./actionTypes";

export const getEvidenceTypesStart = () => {
    return {
        type: actionTypes.GET_EVIDENCE_TYPES_START,
    };
};

export const getEvidenceTypesSuccess = (evidenceTypes) => {
    return {
        type: actionTypes.GET_EVIDENCE_TYPES_SUCCESS,
        evidenceTypes: evidenceTypes,
    };
};

export const getEvidenceTypesFail = (error) => {
    return {
        type: actionTypes.GET_EVIDENCE_TYPES_FAIL,
        error: error,
    };
};

export const getEvidenceTypes = () => {
    return (dispatch) => {
        dispatch(getEvidenceTypesStart());

        return EvidenceAPI.getEvidenceTypes()
            .then((res) => {
                dispatch(getEvidenceTypesSuccess(res));
            })
            .catch((err) => {
                dispatch(getEvidenceTypesFail(err));
            });
    };
};

export const getEvidencesByAccountIdStart = () => {
    return {
        type: actionTypes.GET_EVIDENCES_BYACCOUNTID_START,
    };
};

export const getEvidencesByAccountIdSuccess = (evidences) => {
    return {
        type: actionTypes.GET_EVIDENCES_BYACCOUNTID_SUCCESS,
        evidences: evidences,
    };
};

export const getEvidencesByAccountIdFail = (error) => {
    return {
        type: actionTypes.GET_EVIDENCES_BYACCOUNTID_FAIL,
        error: error,
    };
};

export const getEvidencesByAccountId = () => {
    return (dispatch) => {
        dispatch(getEvidencesByAccountIdStart());

        return EvidenceAPI.getEvidencesByAccountId()
            .then((res) => {
                dispatch(getEvidencesByAccountIdSuccess(res));
            })
            .catch((err) => {
                dispatch(getEvidencesByAccountIdFail(err));
            });
    };
};

export const getEvidencesByManagedPeopleAccountIdStart = () => {
    return {
        type: actionTypes.GET_EVIDENCES_BY_MANAGE_PEOPLE_ACCOUNTID_START,
    };
};

export const getEvidencesByManagedPeopleAccountIdSuccess = (evidences) => {
    return {
        type: actionTypes.GET_EVIDENCES_BY_MANAGE_PEOPLE_ACCOUNTID_SUCCESS,
        evidences: evidences,
    };
};

export const getEvidencesByManagedPeopleAccountIdFail = (error) => {
    return {
        type: actionTypes.GET_EVIDENCES_BY_MANAGE_PEOPLE_ACCOUNTID_FAIL,
        error: error,
    };
};

export const getEvidencesByManagedPeopleAccountId = (email) => {
    return (dispatch) => {
        dispatch(getEvidencesByManagedPeopleAccountIdStart());

        return EvidenceAPI.getEvidencesByManagedPeopleAccountId(email)
            .then((res) => {
                dispatch(getEvidencesByManagedPeopleAccountIdSuccess(res));
            })
            .catch((err) => {
                dispatch(getEvidencesByManagedPeopleAccountIdFail(err));
            });
    };
};

export const addEvidenceStart = () => {
    return {
        type: actionTypes.ADD_EVIDENCE_START,
    };
};

export const addEvidenceSuccess = (evidence) => {
    return {
        type: actionTypes.ADD_EVIDENCE_SUCCESS,
        evidence: evidence,
    };
};

export const addEvidenceFail = (error) => {
    return {
        type: actionTypes.ADD_EVIDENCE_FAIL,
        error: error,
    };
};

export const addEvidence = (evidence) => {
    return (dispatch) => {
        dispatch(addEvidenceStart());

        return EvidenceAPI.addEvidence(evidence)
            .then((res) => {
                dispatch(addEvidenceSuccess(res));
            })
            .catch((err) => {
                dispatch(addEvidenceFail(err));
            });
    };
};

export const addEvidencesStart = () => {
    return {
        type: actionTypes.ADD_EVIDENCES_START,
    };
};

export const addEvidencesSuccess = (evidences) => {
    return {
        type: actionTypes.ADD_EVIDENCES_SUCCESS,
        evidences: evidences,
    };
};

export const addEvidencesFail = (error) => {
    return {
        type: actionTypes.ADD_EVIDENCES_FAIL,
        error: error,
    };
};

export const addEvidences = (evidences) => {
    return (dispatch) => {
        dispatch(addEvidencesStart());

        return EvidenceAPI.addEvidences(evidences)
            .then((res) => {
                dispatch(addEvidencesSuccess(res));
            })
            .catch((err) => {
                dispatch(addEvidencesFail(err));
            });
    };
};

export const addManagedPeopleEvidenceStart = () => {
    return {
        type: actionTypes.ADD_MANAGED_PEOPLE_EVIDENCE_START,
    };
};

export const addManagedPeopleEvidenceSuccess = (evidence) => {
    return {
        type: actionTypes.ADD_MANAGED_PEOPLE_EVIDENCE_SUCCESS,
        evidence: evidence,
    };
};

export const addManagedPeopleEvidenceFail = (error) => {
    return {
        type: actionTypes.ADD_MANAGED_PEOPLE_EVIDENCE_FAIL,
        error: error,
    };
};

export const addManagedPeopleEvidence = (evidence) => {
    return (dispatch) => {
        dispatch(addManagedPeopleEvidenceStart());

        return EvidenceAPI.addManagedPeopleEvidence(evidence)
            .then((res) => {
                dispatch(addManagedPeopleEvidenceSuccess(res));
            })
            .catch((err) => {
                dispatch(addManagedPeopleEvidenceFail(err));
            });
    };
};

export const updateEvidenceStart = () => {
    return {
        type: actionTypes.UPDATE_EVIDENCE_START,
    };
};

export const updateEvidenceSuccess = (evidence) => {
    return {
        type: actionTypes.UPDATE_EVIDENCE_SUCCESS,
        evidence: evidence,
    };
};

export const updateEvidenceFail = (error) => {
    return {
        type: actionTypes.UPDATE_EVIDENCE_FAIL,
        error: error,
    };
};

export const updateEvidence = (evidenceId, evidence) => {
    return (dispatch) => {
        dispatch(updateEvidenceStart());

        return EvidenceAPI.updateEvidence(evidenceId, evidence)
            .then((res) => {
                dispatch(updateEvidenceSuccess(res));
            })
            .catch((err) => {
                dispatch(updateEvidenceFail(err));
            });
    };
};

export const updateManagedPeopleEvidenceStart = () => {
    return {
        type: actionTypes.UPDATE_EVIDENCE_START,
    };
};

export const updateManagedPeopleEvidenceSuccess = (evidence) => {
    return {
        type: actionTypes.UPDATE_EVIDENCE_SUCCESS,
        evidence: evidence,
    };
};

export const updateManagedPeopleEvidenceFail = (error) => {
    return {
        type: actionTypes.UPDATE_EVIDENCE_FAIL,
        error: error,
    };
};

export const updateManagedPeopleEvidence = (evidenceId, evidence) => {
    return (dispatch) => {
        dispatch(updateManagedPeopleEvidenceStart());

        return EvidenceAPI.updateManagedPeopleEvidence(evidenceId, evidence)
            .then((res) => {
                dispatch(updateManagedPeopleEvidenceSuccess(res));
            })
            .catch((err) => {
                dispatch(updateManagedPeopleEvidenceFail(err));
            });
    };
};

export const deleteEvidenceStart = () => {
    return {
        type: actionTypes.DELETE_EVIDENCE_START,
    };
};

export const deleteEvidenceSuccess = () => {
    return {
        type: actionTypes.DELETE_EVIDENCE_SUCCESS,
    };
};

export const deleteEvidenceFail = (error) => {
    return {
        type: actionTypes.DELETE_EVIDENCE_FAIL,
        error: error,
    };
};

export const deleteEvidence = (evidenceId) => {
    return (dispatch) => {
        dispatch(deleteEvidenceStart());

        return EvidenceAPI.deleteEvidence(evidenceId)
            .then((res) => {
                dispatch(deleteEvidenceSuccess(res));
            })
            .catch((err) => {
                dispatch(deleteEvidenceFail(err));
            });
    };
};

export const deleteManagedPeopleEvidenceStart = () => {
    return {
        type: actionTypes.DELETE_MANAGED_PEOPLE_EVIDENCE_START,
    };
};

export const deleteManagedPeopleEvidenceSuccess = () => {
    return {
        type: actionTypes.DELETE_MANAGED_PEOPLE_EVIDENCE_SUCCESS,
    };
};

export const deleteManagedPeopleEvidenceFail = (error) => {
    return {
        type: actionTypes.DELETE_MANAGED_PEOPLE_EVIDENCE_FAIL,
        error: error,
    };
};

export const deleteManagedPeopleEvidence = (evidenceId, personEmail) => {
    return (dispatch) => {
        dispatch(deleteManagedPeopleEvidenceStart());

        return EvidenceAPI.deleteManagedPeopleEvidence(evidenceId, personEmail)
            .then((res) => {
                dispatch(deleteManagedPeopleEvidenceSuccess(res));
            })
            .catch((err) => {
                dispatch(deleteManagedPeopleEvidenceFail(err));
            });
    };
};

export const downloadEvidenceFileByIdStart = () => {
    return {
        type: actionTypes.DOWNLOAD_EVIDENCE_BYID_START,
    };
};

export const downloadEvidenceFileByIdSuccess = (evidenceFile) => {
    console.log("evidenceFile", evidenceFile);
    return {
        type: actionTypes.DOWNLOAD_EVIDENCE_BYID_SUCCESS,
        evidenceFile: evidenceFile,
    };
};

export const downloadEvidenceFileByIdFail = (error) => {
    return {
        type: actionTypes.DOWNLOAD_EVIDENCE_BYID_FAIL,
        error: error,
    };
};

export const downloadEvidenceFileById = (id) => {
    return (dispatch) => {
        dispatch(downloadEvidenceFileByIdStart());

        return EvidenceAPI.downloadEvidence(id)
            .then((res) => {
                dispatch(downloadEvidenceFileByIdSuccess(res));
            })
            .catch((err) => {
                dispatch(downloadEvidenceFileByIdFail(err));
            });
    };
};

export const downloadManagedPeopleEvidenceFileByIdStart = () => {
    return {
        type: actionTypes.DOWNLOAD_MANAGED_PEOPLE_EVIDENCE_BYID_START,
    };
};

export const downloadManagedPeopleEvidenceFileByIdSuccess = (evidenceFile) => {
    return {
        type: actionTypes.DOWNLOAD_MANAGED_PEOPLE_EVIDENCE_BYID_SUCCESS,
        evidenceFile: evidenceFile,
    };
};

export const downloadManagedPeopleEvidenceFileByIdFail = (error) => {
    return {
        type: actionTypes.DOWNLOAD_MANAGED_PEOPLE_EVIDENCE_BYID_FAIL,
        error: error,
    };
};

export const downloadManagedPeopleEvidenceFileById = (email, id) => {
    return (dispatch) => {
        dispatch(downloadManagedPeopleEvidenceFileByIdStart());

        return EvidenceAPI.downloadManagedPeopleEvidence(email, id)
            .then((res) => {
                dispatch(downloadManagedPeopleEvidenceFileByIdSuccess(res));
            })
            .catch((err) => {
                dispatch(downloadManagedPeopleEvidenceFileByIdFail(err));
            });
    };
};
